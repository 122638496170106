<div class="group relative z-[50]">
  <img src="assets/icons/table-info.svg" />
  <div
    class="absolute bottom-0 left-[200%] hidden w-80 flex-col gap-4 rounded-md bg-zinc-600 p-4 font-sans text-xs text-white group-hover:flex"
  >
    <span class="text-left text-sm font-bold">Tastenkürzel zur Bearbeitung der Tabelle</span>
    <div class="flex flex-col gap-4">
      <div class="flex items-center gap-4">
        <span class="w-full">Horizontale Linie einfügen</span>
        <div class="flex flex-row gap-1">
          <span class="rounded bg-primary px-2 py-1 text-black">Linksklick</span>
        </div>
      </div>
      <div class="flex items-center gap-4">
        <span class="w-full">Vertikale Linie einfügen</span>
        <div class="flex flex-row gap-1">
          <span class="rounded bg-primary px-2 py-1 text-black">Strg</span>
          <span class="rounded bg-primary px-2 py-1 text-black">Linksklick</span>
        </div>
      </div>
      <div class="flex items-center gap-4">
        <span class="w-full">Beide Linien einfügen</span>
        <div class="flex flex-row gap-1">
          <span class="rounded bg-primary px-2 py-1 text-black">Alt</span>
          <span class="rounded bg-primary px-2 py-1 text-black">Linksklick</span>
        </div>
      </div>
    </div>
  </div>
</div>
