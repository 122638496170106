import { Component, inject, Input } from '@angular/core';
import { AsyncPipe, NgClass } from '@angular/common';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import { CtPage } from '../../../../models/ct-batch-model';
import { LoadImagePipe, PageImageService } from '../../../../services/api/page-image.service';

@Component({
  selector: 'app-image-list-preview-item-document',
  standalone: true,
  imports: [NgClass, MatProgressSpinner, LoadImagePipe, AsyncPipe],
  templateUrl: './image-list-preview-item-document.component.html',
})
export class ImageListPreviewItemDocumentComponent {
  @Input({ required: true }) typeThumbnail!: CtPage;
  @Input({ required: true }) selected!: boolean;
  @Input({ required: true }) documentType!: string;

  protected pageImageService = inject(PageImageService);
}
