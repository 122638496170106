import { inject, Injectable } from '@angular/core';
import { Config } from '../../models/interfaces';
import { ConfigService } from '../config/config.service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export abstract class ApiService {
  protected configService = inject(ConfigService);
  protected httpClient = inject(HttpClient);

  private readonly baseApiUrl: string;

  protected constructor(baseUrlConfigKey: keyof Config, basePath?: string) {
    this.baseApiUrl =
      this.configService.config[baseUrlConfigKey] + (basePath ? '/' + basePath : '');
  }

  protected get<R>(path: string | number, options: object | undefined = undefined): Observable<R> {
    return this.httpClient.get<R>(this.getUrl(String(path)), options);
  }

  protected put<R, D>(path: string, data: D): Observable<R> {
    return this.httpClient.put<R>(this.getUrl(path), data);
  }

  protected delete(path: string): Observable<unknown> {
    return this.httpClient.delete<unknown>(this.getUrl(path));
  }

  protected post<R, D>(
    path: string,
    data: D,
    options: object | undefined = undefined,
  ): Observable<R> {
    return this.httpClient.post<R>(this.getUrl(path), data, options);
  }

  protected patch<R, D>(path: string, data: D): Observable<R> {
    return this.httpClient.patch<R>(this.getUrl(path), data);
  }

  private getUrl(path: string | number): string {
    return this.baseApiUrl + '/' + path;
  }
}
