<p-confirmDialog #completionDialog key="completionDialog">
  <ng-template pTemplate="headless">
    <app-dialog
      acceptText="Bestätigen & Senden"
      closeText="Abbrechen"
      title="Vorgang abschließen"
      (acceptDialog)="acceptedDialog.emit(); completionDialog.accept()"
      (closeDialog)="completionDialog.reject()"
    >
      <div class="flex w-full flex-col gap-y-3 p-6">
        <div class="flex flex-col">
          <div class="flex items-center gap-2">
            <span class="font-bold">Forderungsdatum</span>
            <button class="flex" (click)="clickedEdit.emit()">
              <mat-icon>edit</mat-icon>
            </button>
          </div>
          <span [ngClass]="{ italic: !extractedFieldsObject.InvoiceDate }">{{
            extractedFieldsObject.InvoiceDate
              ? (extractedFieldsObject.InvoiceDate | localizedDate)
              : "Kein Datum extrahiert"
          }}</span>
        </div>
        <div class="flex flex-col">
          <div class="flex items-center gap-2">
            <span class="font-bold">Geburtsdatum</span>
            <button class="flex" (click)="clickedEdit.emit()">
              <mat-icon>edit</mat-icon>
            </button>
          </div>
          <span [ngClass]="{ italic: !extractedFieldsObject.ClaimantBirthDate }">{{
            extractedFieldsObject.ClaimantBirthDate
              ? (extractedFieldsObject.ClaimantBirthDate | localizedDate)
              : "Kein Datum extrahiert"
          }}</span>
        </div>
        <div class="flex flex-col">
          <div class="flex items-center gap-2">
            <span class="font-bold">Kostenträgerzeichen</span>
            <button class="flex" (click)="clickedEdit.emit()">
              <mat-icon>edit</mat-icon>
            </button>
          </div>
          <span [ngClass]="{ italic: !extractedFieldsObject.KstSign }">{{
            extractedFieldsObject.KstSign ?? "Kein Zeichen extrahiert"
          }}</span>
        </div>
        <div class="flex flex-col">
          <div class="flex items-center gap-2">
            <span class="font-bold">Forderungen</span>
            <button class="flex" (click)="clickedForderungenEdit.emit(); completionDialog.reject()">
              <mat-icon>edit</mat-icon>
            </button>
          </div>
          @if (auditPositionArray.length > 0) {
            <span>Forderungen ({{ auditPositionArray.length }})</span>
          } @else {
            <span class="italic">Keine Forderungen extrahiert</span>
          }
        </div>
      </div>
    </app-dialog>
  </ng-template>
</p-confirmDialog>
