<div
  [style.width.px]="columnWidth"
  class="relative flex w-fit flex-col items-center justify-center"
>
  @if (!tableData.confirmed) {
    <p-menu
      #menu
      [model]="menuModel"
      class="absolute bottom-0"
      [styleClass]="'header-menu z-20 -translate-x-1/2' + getTranslate()"
      [attr.data-col-index]="columnIndex"
      [ngClass]="isMenuOpened ? 'visible' : 'invisible'"
    />
  }
  @if (!tableData.confirmed || this.tableData.columnHeaders[this.columnIndex]) {
    <button
      class="pointer-events-auto relative flex flex-col items-center justify-center rounded-xl"
      [ngClass]="
        !isMenuOpened
          ? this.tableData.columnHeaders[this.columnIndex] === NOT_IMPORTANT_TABLE_HEADER
            ? 'bg-theme-gray-600 text-white'
            : 'bg-theme-dark-blue text-white'
          : 'border border-theme-dark-blue bg-[#E9F4F8] text-theme-dark-blue'
      "
      (click)="columnButtonClicked.emit(columnIndex); $event.stopPropagation()"
    >
      <div
        style="writing-mode: vertical-rl"
        class="-rotate-180"
        [ngClass]="{ 'leading-[0]': !this.tableData.columnHeaders[this.columnIndex] }"
      >
        @if (this.tableData.columnHeaders[this.columnIndex]; as header) {
          <span class="block px-0.5 py-1.5 text-xs">
            {{ header }}
          </span>
        } @else {
          <mat-icon>add</mat-icon>
        }
      </div>
    </button>
  }
</div>
