import {
  Component,
  effect,
  ElementRef,
  inject,
  OnInit,
  signal,
  ViewChild,
  WritableSignal,
} from '@angular/core';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { LocalizedDatePipe } from '../../pipes/localized-date.pipe';
import { MatIcon } from '@angular/material/icon';
import { Confirmation, ConfirmationService, SharedModule } from 'primeng/api';
import { NgStyle } from '@angular/common';
import { CtBatchStoreService } from '../../services/app-state/ct-batch-store.service';
import { DocumentPreviewComponent } from '../../domains/preview/document-preview.component';
import { CompletionDialogComponent } from '../../domains/dialogs/completion-dialog/completion-dialog.component';
import { DataCapturingTableViewComponent } from '../../domains/data-capturing/data-capturing-table-view/data-capturing-table-view.component';
import { WorkflowService } from '../../services/app-state/workflow.service';
import { DiscardTableDialogComponent } from '../../domains/dialogs/discard-table-dialog/discard-table-dialog.component';
import { TableOverlayStoreService } from '../../services/overlay/table-overlay-store.service';
import { ExtractedDataStoreService } from '../../services/extracted-data/extracted-data-store.service';
import { UntilDestroy } from '@ngneat/until-destroy';
import { CtBatch, CtDocument } from '../../models/ct-batch-model';

@UntilDestroy()
@Component({
  selector: 'app-table-extraction-page',
  standalone: true,
  imports: [
    ConfirmDialogModule,
    LocalizedDatePipe,
    MatIcon,
    SharedModule,
    NgStyle,
    DocumentPreviewComponent,
    DataCapturingTableViewComponent,
    CompletionDialogComponent,
    DiscardTableDialogComponent,
  ],
  templateUrl: './table-extraction-page.component.html',
})
export class TableExtractionPageComponent implements OnInit {
  private ctBatchStoreService = inject(CtBatchStoreService);
  protected workflowService = inject(WorkflowService);
  private confirmationService = inject(ConfirmationService);
  private tableOverlayStoreService = inject(TableOverlayStoreService);
  private extractedDataStoreService = inject(ExtractedDataStoreService);

  ctBatch!: CtBatch;
  currentDocument!: CtDocument;
  forderungsaufstellungDocuments!: CtDocument[];

  @ViewChild('formElementContainer') formElementContainer!: ElementRef<HTMLDivElement>;
  @ViewChild('completionDialog') completionDialog!: Confirmation;
  @ViewChild('discardTableDialog') discardTableDialog!: Confirmation;

  currentDocumentIndex: WritableSignal<number> = signal(0);

  constructor() {
    effect(() => {
      const currIndex = this.currentDocumentIndex();
      const document = structuredClone(this.ctBatchStoreService.ctBatch);
      this.currentDocument = this.forderungsaufstellungDocuments[currIndex];

      this.ctBatch = document;
    });
  }

  ngOnInit() {
    this.ctBatch = structuredClone(this.ctBatchStoreService.ctBatch);

    const forderungsaufstellungen = this.ctBatchStoreService.forderungsaufstellungen;

    if (forderungsaufstellungen.length > 0) {
      this.forderungsaufstellungDocuments = forderungsaufstellungen;
      this.currentDocument = forderungsaufstellungen[this.currentDocumentIndex()];
    }
  }

  setPrevDocument(allTablesConfirmed: boolean): void {
    if (this.currentDocumentIndex() === 0) {
      if (allTablesConfirmed) {
        this.workflowService.navigateToPrevView();
        return;
      }

      this.discardTableDialog.key = 'discardTableDialog';
      this.confirmationService.confirm(this.discardTableDialog);
      return;
    }

    this.currentDocumentIndex.update((value) => value - 1);
  }

  setNextDocument() {
    if (this.currentDocumentIndex() >= this.forderungsaufstellungDocuments.length - 1) {
      this.completionDialog.key = 'completionDialog';
      this.confirmationService.confirm(this.completionDialog);
      return;
    }

    this.currentDocumentIndex.update((value) => value + 1);
  }

  discardAndGoToPrevView() {
    this.tableOverlayStoreService.deleteUnconfirmedTables();
    this.workflowService.navigateToPrevView();
  }

  completeProcess() {
    const auditId = this.ctBatchStoreService.ctBatch.auditId;

    if (!auditId) return;

    this.workflowService.navigateToNextView();
  }

  getExtractedFieldObject() {
    return this.extractedDataStoreService.extractedFields;
  }

  getAuditPositionArray() {
    return this.extractedDataStoreService.auditPositionArray;
  }
}
