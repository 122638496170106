import { Pipe, PipeTransform } from '@angular/core';
import { WordRect } from '../../../models/interfaces';

@Pipe({
  name: 'rectDisplayStyle',
  standalone: true,
})
export class RectDisplayStylePipe implements PipeTransform {
  transform(rect: WordRect): Record<string, string> {
    return {
      left: `${rect.x[0]}px`,
      top: `${rect.y[0]}px`,
      width: `${rect.x[1] - rect.x[0]}px`,
      height: `${rect.y[1] - rect.y[0]}px`,
    };
  }
}
