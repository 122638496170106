import { inject, Injectable } from '@angular/core';
import { CtBatch, CtDocument } from '../../models/ct-batch-model';
import { PersistDataService } from './persist-data.service';

@Injectable({
  providedIn: 'root',
})
export class CtBatchStoreService {
  private persistDataService = inject(PersistDataService);

  ctBatch: CtBatch = {} as CtBatch;

  private _ctBatchId: string | null = this.persistDataService.ctBatchId;

  set ctBatchId(ctBatchId: string) {
    this._ctBatchId = ctBatchId;
    this.persistDataService.ctBatchId = ctBatchId;
  }

  get ctBatchId(): string | null {
    return this._ctBatchId;
  }

  get forderungsaufstellungen(): CtDocument[] {
    return this.ctBatch.documents.filter((document) => {
      return document.documentClass === 'FORDERUNGSAUFSTELLUNG';
    });
  }
}
