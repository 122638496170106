<div class="flex w-[33vw] flex-col bg-white">
  <div class="flex flex-row justify-between border-b border-theme-gray-500 p-6">
    <span class="block w-fit text-xl">{{ title }}</span>
    <button class="flex items-center justify-center">
      <mat-icon [ngStyle]="{ color: '#575757' }" (click)="closeDialog.emit()">close</mat-icon>
    </button>
  </div>
  <div>
    <ng-content></ng-content>
  </div>
  <div class="flex h-16 flex-row border-t border-theme-gray-500 text-xs">
    <button (click)="closeDialog.emit()" class="w-1/2 font-bold uppercase">
      {{ closeText }}
    </button>
    <button
      (click)="acceptDialog.emit(); $event.stopPropagation()"
      (mouseup)="$event.stopPropagation()"
      (mousedown)="$event.stopPropagation()"
      class="w-1/2 bg-primary font-bold uppercase"
    >
      {{ acceptText }}
    </button>
  </div>
</div>
