@if (this.shouldDisplayHorizontalPreviewLine) {
  <div
    class="pointer-events-none absolute w-full border border-primary"
    [style.top.px]="getTableYInPixels(mouseY)"
  ></div>
}

@if (this.shouldDisplayVerticalPreviewLine) {
  <div
    class="pointer-events-none absolute h-full border border-[#72c8e5]"
    [style.left.px]="getTableXInPixels(mouseX)"
  ></div>
}
