<div
  class="group pointer-events-auto bottom-0 flex h-full w-full -translate-x-8 flex-row items-center justify-start"
>
  <button
    class="pointer-events-auto hidden h-[26px] w-[26px] items-center justify-center rounded-md bg-theme-gray-500 group-hover:flex"
    (click)="buttonClicked.emit(); $event.stopPropagation()"
    (mousedown)="$event.preventDefault(); $event.stopPropagation()"
  >
    @if (isIgnored) {
      <mat-icon>add</mat-icon>
    } @else {
      <mat-icon>close</mat-icon>
    }
  </button>
</div>
