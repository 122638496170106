import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { tableHeaders, TableRow } from '../../../../models/interfaces';
import { NgClass, NgStyle } from '@angular/common';
import { MatIcon } from '@angular/material/icon';
import { TooltipModule } from 'primeng/tooltip';
import { DataCapturingTableItemComponent } from './data-capturing-table-item/data-capturing-table-item.component';
import { getWidthForInput } from '../../../../utils/DataCapturingTableWidth.utils';
import { MatTooltip } from '@angular/material/tooltip';
import { WaMutationObserver } from '@ng-web-apis/mutation-observer';

@Component({
  selector: 'app-data-capturing-table',
  templateUrl: './data-capturing-table.component.html',
  standalone: true,
  imports: [
    NgClass,
    WaMutationObserver,
    NgStyle,
    MatIcon,
    TooltipModule,
    DataCapturingTableItemComponent,
    MatTooltip,
  ],
})
export class DataCapturingTableComponent {
  @Input({ required: true }) tableRows: TableRow[] = [];
  @Input({ required: true }) currentFieldIndex: number | undefined = 0;
  @Output() tableElementsChanged = new EventEmitter();
  @Output() rowAdded = new EventEmitter<{
    index: number;
    tableId: string | undefined;
    pageId: string | undefined;
  }>();
  @Output() rowDeleted = new EventEmitter<number>();
  @Output() updateCurrentFieldIndex = new EventEmitter<number>();
  @Output() setRowData = new EventEmitter<{ event: Event; header: string; rowIndex: number }>();
  @Output() addRow = new EventEmitter<number>();

  @ViewChild('table') table!: ElementRef<HTMLDivElement>;

  getTableElement(): HTMLDivElement {
    return this.table.nativeElement;
  }

  protected readonly tableHeader = tableHeaders;
  protected readonly getWidthForInput = getWidthForInput;
}
