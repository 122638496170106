import { Component, inject, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap, RouterOutlet } from '@angular/router';
import { FooterComponent } from './domains/navigation/footer/footer.component';
import { HeaderComponent } from './domains/navigation/header/header.component';
import { CtBatchStoreService } from './services/app-state/ct-batch-store.service';
import { ConfirmService } from '@l21s-ecnps/gui-commons';
import { map, switchMap } from 'rxjs';
import { BuildSimpleExtractionResultDto } from './build-simple/build-simple-model';
import { CtBatchApiService } from './services/api/ct-batch-api.service';
import { BuildSimpleExtractedDataProcessingService } from './build-simple/build-simple-extracted-data-processing.service';
import { PageImageService } from './services/api/page-image.service';
import { WorkflowService } from './services/app-state/workflow.service';
import { OcrService } from './services/ocr/ocr.service';
import { AuthenticationService } from './services/authentication.service';
import { ConfigService } from './services/config/config.service';

@Component({
  selector: 'app-batch',
  standalone: true,
  imports: [FooterComponent, HeaderComponent, RouterOutlet],
  templateUrl: './batch.component.html',
})
export class BatchComponent implements OnInit {
  private route = inject(ActivatedRoute);
  private ctBatchStoreService = inject(CtBatchStoreService);
  private authenticationService = inject(AuthenticationService);
  private confirmService = inject(ConfirmService);
  private ctBatchApiService = inject(CtBatchApiService);
  private buildSimpleExtractedDataProcessingService = inject(
    BuildSimpleExtractedDataProcessingService,
  );
  private pageImageService = inject(PageImageService);
  private workflowService = inject(WorkflowService);
  private ocrService = inject(OcrService);
  private configService = inject(ConfigService);

  protected usersFullName = '<loading user>';
  protected doneLoading = false;

  ngOnInit(): void {
    this.authenticationService
      .loadUserProfile()
      .then((userProfile) => {
        this.usersFullName = userProfile.username ?? '<unknown user>';

        this.route.paramMap.subscribe((params) => {
          const retrievedCtBatchId = this.getRouteBatchIdParameter(params);
          const savedCtBatchId = this.ctBatchStoreService.ctBatchId;

          this.ctBatchStoreService.ctBatchId = retrievedCtBatchId;

          const subscription = this.loadCtBatch(
            retrievedCtBatchId,
            savedCtBatchId !== retrievedCtBatchId &&
              !this.configService.config.ignoreBuildSimpleExtractionResults,
          );
          this.confirmService.spinner({ subscription });
        });
      })
      .catch(console.error);
  }

  private getRouteBatchIdParameter(params: ParamMap) {
    const ctBatchId = params.get('id');

    if (!ctBatchId) {
      throw new Error('Invalid path - expected /batch/{batch-id}');
    }

    return ctBatchId;
  }

  loadCtBatch(ctBatchId: string, shouldUtilizeExtractionData: boolean) {
    return this.ctBatchApiService
      .getCtBatch(ctBatchId)
      .pipe(
        switchMap((batch) =>
          this.ctBatchApiService
            .loadExtractionDataByDocumentId(batch)
            .pipe(map((extractionDataByDocumentId) => ({ batch, extractionDataByDocumentId }))),
        ),
      )
      .subscribe({
        next: (result) => {
          if (shouldUtilizeExtractionData) {
            this.buildSimpleExtractedDataProcessingService.storeExtractedSuggestions(
              result.batch,
              result.extractionDataByDocumentId as Record<string, BuildSimpleExtractionResultDto>,
            );
          }

          this.ctBatchStoreService.ctBatch = result.batch;
          this.pageImageService.init(
            result.batch.documents.flatMap((document) => document.pages.map((page) => page.id)),
          );

          this.handleItemStatus();
        },
        error: (error) => {
          console.error(error);
          this.confirmService.snackError(`Vorgang ${ctBatchId} konnte nicht geladen werden`);
        },
      });
  }

  handleItemStatus() {
    const ctBatch = this.ctBatchStoreService.ctBatch;

    const subscription = this.ctBatchApiService.getCtBatchLock(ctBatch.id).subscribe((lock) => {
      if (!lock) {
        this.lockCtBatch();
      } else {
        const lastLockedUser = lock.username;

        if (this.usersFullName !== lastLockedUser) {
          this.confirmService.snackError(
            `Vorgang ${ctBatch.id} ist bereits durch den Nutzer ${lastLockedUser} gesperrt`,
          );
        } else {
          this.workflowService.init();
          this.ocrService.init().subscribe(() => {
            this.doneLoading = true;
          });
        }
      }
    });

    this.confirmService.spinner({ subscription });
  }

  lockCtBatch() {
    this.ctBatchApiService
      .lockCtBatch(this.ctBatchStoreService.ctBatch.id, this.authenticationService.getUserName())
      .pipe(
        switchMap(() => {
          this.workflowService.init();
          return this.ocrService.init();
        }),
      )
      .subscribe({
        complete: () => (this.doneLoading = true),
        error: (error) => {
          console.error(error);
          this.confirmService.snackError(
            `Fehler beim Sperren des Vorgangs mit der ID ${this.ctBatchStoreService.ctBatch.id}.`,
          );
        },
      });
  }
}
