<div
  class="flex h-fit w-full cursor-pointer flex-col items-center gap-2 rounded-md p-2"
  [ngClass]="{
    'bg-[#E9F4F8]/40': selected,
  }"
>
  <img
    [src]="page.id | loadImage | async"
    alt="Page Image"
    loading="lazy"
    class="relative z-[3] h-full rounded-md border-[3px] bg-white object-contain"
    [ngClass]="selected ? 'border-theme-blue' : 'border-theme-gray-400'"
  />
  <span
    class="rounded-full px-[5px]"
    [ngClass]="selected ? 'bg-theme-blue font-bold text-white' : 'bg-theme-gray-400 text-black'"
    >{{ index + 1 }}</span
  >
</div>
