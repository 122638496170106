import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatIcon } from '@angular/material/icon';

@Component({
  selector: 'app-table-overlay-row-remove-container',
  standalone: true,
  imports: [MatIcon],
  templateUrl: './table-overlay-row-remove-container.component.html',
  styles: [
    `
      :host {
        @apply h-full w-full;
      }
    `,
  ],
})
export class TableOverlayRowRemoveContainerComponent {
  @Output() buttonClicked = new EventEmitter<void>();
  @Input({ required: true }) isIgnored = false;
}
