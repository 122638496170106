import { Injectable } from '@angular/core';
import { catchError, forkJoin, map, Observable, of } from 'rxjs';
import { ApiService } from './api.service';
import { CtBatch, CtBatchLock } from '../../models/ct-batch-model';

@Injectable({
  providedIn: 'root',
})
export class CtBatchApiService extends ApiService {
  constructor() {
    super('apiBaseUrl', 'api/batch');
  }

  getCtBatch(ctBatchId: string): Observable<CtBatch> {
    return super.get<CtBatch>(`search/${ctBatchId}`);
  }

  getCtBatchLock(ctBatchId: string): Observable<CtBatchLock | undefined> {
    return super.get<CtBatchLock | undefined>(`lock/${ctBatchId}`);
  }

  lockCtBatch(ctBatchId: string, keycloakUsername: string): Observable<void> {
    return super.post(`lock/${ctBatchId}`, {
      username: keycloakUsername,
    });
  }

  unlockCtBatch(batchId: string): Observable<unknown> {
    return super.delete(`lock/${batchId}`);
  }

  changeCtBatchStateToDone(batchId: string): Observable<void> {
    return this.put(`${batchId}/state`, {
      state: 'DONE',
    });
  }

  loadExtractionDataByDocumentId(ctBatch: CtBatch): Observable<Record<string, object>> {
    return forkJoin(
      ctBatch.documents.map((document) =>
        this.get<object>(`${ctBatch.id}/document/${document.id}/extraction`).pipe(
          map((extractionData) => [document.id, extractionData] as const),
          catchError(() => {
            console.warn(`no extraction data found for document id ${document.id}`);
            return of([document.id, {}] as const);
          }),
        ),
      ),
    ).pipe(
      map((documentIdExtractionDataPairs) =>
        Object.fromEntries(
          documentIdExtractionDataPairs.filter(
            ([_, extractionData]) => Object.keys(extractionData).length > 0,
          ),
        ),
      ),
    );
  }
}
