<!--TODO:general dialog component?-->
<p-confirmDialog #discardTableDialog key="discardTableDialog">
  <ng-template pTemplate="headless">
    <app-dialog
      acceptText="Verwerfen & Zurück"
      closeText="Abbrechen"
      title="Tabellenentwurf verwerfen"
      (acceptDialog)="acceptedDialog.emit(); discardTableDialog.accept()"
      (closeDialog)="discardTableDialog.reject()"
    >
      <p class="p-6">
        Das Layout der Tabelle wurde bisher noch nicht abgeschlossen. Die Änderungen werden
        verworfen, wenn Du zurück zur Erfassung der Vorgangsdaten gehst.
      </p>
    </app-dialog>
  </ng-template>
</p-confirmDialog>
