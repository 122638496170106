<p-confirmDialog
  #deletionDialog
  key="deletionDialog-{{ this.tableData.pageId }}-{{ tableData.id }}"
>
  <ng-template pTemplate="headless">
    <app-dialog
      acceptText="Bestätigen & Löschen"
      closeText="Abbrechen"
      title="Tabelle löschen"
      (acceptDialog)="acceptedDialog.emit(); deletionDialog.accept()"
      (closeDialog)="deletionDialog.reject()"
    >
      <p class="p-6">Soll diese Tabelle gelöscht werden?</p>
    </app-dialog>
  </ng-template>
</p-confirmDialog>
