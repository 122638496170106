import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TooltipModule } from 'primeng/tooltip';
import { MatIcon } from '@angular/material/icon';
import { TableData } from '../../../../../models/interfaces';
import { MatTooltip } from '@angular/material/tooltip';

@Component({
  selector: 'app-table-overlay-confirm-button',
  standalone: true,
  imports: [TooltipModule, MatIcon, MatTooltip],
  templateUrl: './table-overlay-confirm-button.component.html',
  styleUrl: './table-overlay-confirm-button.component.scss',
})
export class TableOverlayConfirmButtonComponent {
  @Input({ required: true }) tableData!: TableData;

  @Output() buttonClicked = new EventEmitter<void>();

  protected shouldDisableConfirmButton(): boolean {
    return (
      this.tableData.columnHeaders.every((label) => label === undefined) &&
      this.tableData.layoutDefined
    );
  }
}
