export function sanitizeDateInput(input: string): string {
  return (
    input
      .trim()
      .replace(/[^0-9a-zA-Zä.\-/ ]/g, '')
      // März is the only month with an umlaut and "ä" is often recognized as "a" by ocr
      .replace('Marz', 'März')
      // remove all whitespaces which are not between a letter and a digit (or between a digit and a letter)
      // these exceptions keep one whitespace in "...Januar 20..." or "10 März", otherwise
      // such a date would not be parseable anymore (10März or Januar2020).
      // see: https://regexr.com/806b0
      // Note: "10 März" is actually not a common german format, but the period is often not recognized by the ocr
      .replace(/\s+(?!(?<=[a-zA-Z]\s+)\d)(?!(?<=\d\s+)[a-zA-Z])/g, '')
      //remove special characters at start and end
      .replace(/^[^\w\s]+|[^\w\s]+$/g, '')
  );
}
