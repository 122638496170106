import { Component, Input } from '@angular/core';
import { OverlayRectComponent } from '../../overlay-rect/overlay-rect.component';
import { WordRect } from '../../../../models/interfaces';

@Component({
  selector: 'app-text-selection-overlay',
  standalone: true,
  imports: [OverlayRectComponent],
  templateUrl: './text-selection-overlay.component.html',
})
export class TextSelectionOverlayComponent {
  @Input({ required: true }) selectedWordsRect: WordRect[] = [];
  @Input({ required: true }) lasso: WordRect | undefined;
  @Input({ required: true }) pageId!: string;
}
