import { Component, Input } from '@angular/core';
import { NgClass } from '@angular/common';
import {
  Interval,
  remap,
  TableData,
  TableOverlayLine,
  Vector2,
  WordRect,
} from '../../../../../models/interfaces';
import { LassoSelectionDirective } from '../../../../../directives/lasso-selection.directive';

@Component({
  selector: 'app-table-overlay-preview-lines',
  standalone: true,
  imports: [NgClass],
  templateUrl: './table-overlay-preview-lines.component.html',
})
export class TableOverlayPreviewLinesComponent {
  @Input({ required: true }) tableData!: TableData;
  @Input({ required: true }) arePreviewLinesEnabled!: boolean;
  @Input({ required: true }) isCtrlPressed!: boolean;
  @Input({ required: true }) isAltPressed!: boolean;
  @Input({ required: true }) lassoSelection!: LassoSelectionDirective;
  @Input({ required: true }) tableRect!: WordRect;
  @Input({ required: true }) tableRef!: HTMLDivElement;
  @Input({ required: true }) currentDragInfos!: {
    line: TableOverlayLine;
    offsetRestriction: Interval;
  }[];

  protected get mouseX(): number {
    return this.lassoSelection.currentMousePosition()[0];
  }

  protected get mouseY(): number {
    return this.lassoSelection.currentMousePosition()[1];
  }

  protected isMouseInTableRect(): boolean {
    const mousePosition = this.lassoSelection.currentMousePosition();
    return this.isPointInRect(mousePosition, this.tableRect);
  }

  getTableYInPixels(relativeOverlayY: number): number {
    return (
      remap(relativeOverlayY, this.tableRect.y[0], this.tableRect.y[1], 0, 1) *
      this.tableRef.offsetHeight
    );
  }

  getTableXInPixels(relativeOverlayX: number): number {
    return (
      remap(relativeOverlayX, this.tableRect.x[0], this.tableRect.x[1], 0, 1) *
      this.tableRef.offsetWidth
    );
  }

  protected get shouldDisplayHorizontalPreviewLine(): boolean {
    return (
      this.shouldDisplayPreviewLines &&
      (!this.isCtrlPressed || this.isAltPressed) &&
      this.isMouseInTableRect()
    );
  }

  protected get shouldDisplayVerticalPreviewLine(): boolean {
    return (
      this.shouldDisplayPreviewLines &&
      (this.isCtrlPressed || this.isAltPressed) &&
      this.isMouseInTableRect()
    );
  }

  protected isPointInRect(point: Vector2, rect: WordRect): boolean {
    return this.isInInterval(point[0], rect.x) && this.isInInterval(point[1], rect.y);
  }

  protected isInInterval(value: number, interval: Interval) {
    return value >= interval[0] && value <= interval[1];
  }

  private get shouldDisplayPreviewLines(): boolean {
    return (
      this.arePreviewLinesEnabled &&
      this.currentDragInfos.length === 0 &&
      !this.tableData.layoutDefined
    );
  }
}
