import { APP_INITIALIZER, EnvironmentProviders, Provider } from '@angular/core';
import { ConfirmationService, MessageService } from 'primeng/api';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import {
  HTTP_INTERCEPTORS,
  HttpClient,
  provideHttpClient,
  withInterceptorsFromDi,
} from '@angular/common/http';
import { KeycloakBearerInterceptor, KeycloakService } from 'keycloak-angular';
import { provideRouter, Routes } from '@angular/router';
import { keycloakGuard } from './app/guards/auth.guard';
import { BatchComponent } from './app/batch.component';
import { SingleFieldExtractionPageComponent } from './app/pages/single-field-extraction-page/single-field-extraction-page.component';
import { TableExtractionPageComponent } from './app/pages/table-extraction-page/table-extraction-page.component';
import { initializeKeycloak } from './app/keycloak/keycloak-init.factory';
import { ConfigService } from './app/services/config/config.service';
import {
  AuthenticationService,
  KeycloakAuthenticationService,
} from './app/services/authentication.service';

const routes: Routes = [
  {
    path: 'batch/:id',
    canActivate: [keycloakGuard],
    component: BatchComponent,
    children: [
      {
        path: 'fields',
        component: SingleFieldExtractionPageComponent,
      },
      {
        path: 'tables',
        component: TableExtractionPageComponent,
      },
    ],
  },
];

const KeycloakBearerInterceptorProvider: Provider = {
  provide: HTTP_INTERCEPTORS,
  useClass: KeycloakBearerInterceptor,
  multi: true,
};

// Provider for Keycloak Initialization
const KeycloakInitializerProvider: Provider = {
  provide: APP_INITIALIZER,
  useFactory: initializeKeycloak,
  multi: true,
  deps: [KeycloakService, ConfigService],
};

export const getProviders = (): (Provider | EnvironmentProviders)[] => [
  // TODO: I would like to only provide the AuthenticationService here, but how to make sure it is
  // available for the APP_INITIALIZER, HTTP_INTERCEPTORS and KeycloakAuthenticationService itself?
  KeycloakService,
  {
    provide: AuthenticationService,
    useClass: KeycloakAuthenticationService,
  },
  MessageService,
  ConfirmationService,
  provideAnimationsAsync(),
  provideHttpClient(withInterceptorsFromDi()),
  { provide: ConfigService, useClass: ConfigService, deps: [HttpClient] },
  KeycloakInitializerProvider, // Initializes Keycloak
  KeycloakBearerInterceptorProvider, // Provides Keycloak Bearer Interceptor
  provideRouter(routes),
];
