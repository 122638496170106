export const maxBy = <T>(elements: T[], comparisonTransform: (t: T) => number): T | undefined => {
  if (elements.length === 0) {
    return undefined;
  }

  let maxValue = Number.MIN_VALUE;
  let maxElement = elements[0];

  for (const element of elements) {
    const value = comparisonTransform(element);
    if (value > maxValue) {
      maxValue = value;
      maxElement = element;
    }
  }

  return maxElement;
};

export const minBy = <T>(elements: T[], comparisonTransform: (t: T) => number): T | undefined =>
  maxBy(elements, (element) => -comparisonTransform(element));
