import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AuditPatchDto } from '../../models/interfaces';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root',
})
export class PersonenschadenApiService extends ApiService {
  constructor() {
    super('psBackend');
  }

  patchAudit(auditPatchDto: AuditPatchDto, auditId: string): Observable<string> {
    return super.patch<string, AuditPatchDto>(`audit/ct/${auditId}`, auditPatchDto);
  }
}
