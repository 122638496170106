<div
  class="edit-btn-container pointer-events-auto absolute right-1 top-1 hidden flex-row justify-end hover:flex group-hover/edit:flex"
>
  <div class="flex h-fit w-fit items-center gap-1 rounded-lg text-sm">
    @if (tableData.confirmed) {
      <button
        class="flex items-center justify-center rounded-md bg-theme-blue p-0.5 text-white"
        (click)="editButtonClicked.emit(); $event.stopImmediatePropagation()"
        (mousedown)="$event.stopPropagation()"
        (mouseup)="$event.stopPropagation()"
      >
        <mat-icon style="transform: scale(0.8)">edit</mat-icon>
      </button>
      <div class="h-[15px] w-[1px] bg-theme-gray-500"></div>
    } @else if (!tableData.layoutDefined) {
      <button
        class="flex items-center justify-center rounded-md border bg-pink-500 p-1"
        (click)="magicWandButtonClicked.emit(); $event.stopImmediatePropagation()"
        (mousedown)="$event.stopPropagation()"
        (mouseup)="$event.stopPropagation()"
      >
        <img
          src="assets/icons/magic-wand.svg"
          [ngStyle]="{ minWidth: 20, width: 20, height: 20 }"
          loading="lazy"
          alt=""
        />
      </button>
    }
    <button
      class="flex items-center justify-center rounded-md bg-theme-danger p-0.5 text-white"
      (click)="deleteButtonClicked.emit(); $event.stopPropagation()"
      (mousedown)="$event.preventDefault(); $event.stopPropagation()"
      (mouseup)="$event.stopPropagation()"
    >
      <mat-icon style="transform: scale(0.8)">close</mat-icon>
    </button>
  </div>
</div>
