import { Pipe, PipeTransform } from '@angular/core';
import dayjs, { Dayjs } from 'dayjs';

@Pipe({
  name: 'ocrDate',
  standalone: true,
})
export class OcrDatePipe implements PipeTransform {
  private readonly dayFormats = ['DD', 'D'];
  private readonly monthFormats = ['MM', 'M', 'MMM', 'MMMM'];
  private readonly yearFormats = ['YYYY', 'YY'];
  private readonly delimiters = ['.', '-', '/'];

  private readonly allDateFormats = this.yearFormats.flatMap((yearFormat) =>
    this.dayFormats.flatMap((dayFormat) =>
      this.monthFormats.flatMap((monthFormat) =>
        this.getDelimitedFormats(dayFormat, monthFormat, yearFormat),
      ),
    ),
  );

  private getDelimitedFormats(
    dayFormat: string,
    monthFormat: string,
    yearFormat: string,
  ): string[] {
    // We want X∘Y∘Z for every delimiter ∘, except for named months ("Jan.", "Januar")
    switch (monthFormat) {
      // in dayjs, the "MMM" format of a month includes a trailing period, i.e. "Januar" becomes "Jan.",
      // "Februar" becomes "Feb." and so on.
      case 'MMM':
        return [`${dayFormat}.MMM${yearFormat}`, `${dayFormat},MMM${yearFormat}`];
      case 'MMMM':
        return [`${dayFormat} MMMM ${yearFormat}`, `${dayFormat}.MMMM ${yearFormat}`];
      default:
        return this.delimiters.map(
          (delimiter) => dayFormat + delimiter + monthFormat + delimiter + yearFormat,
        );
    }
  }

  transform(value: string): Dayjs {
    return dayjs(value, this.allDateFormats, 'de', true);
  }
}

const ocrDatePipe = new OcrDatePipe();
export const transformDate = (value: string) => ocrDatePipe.transform(value);
