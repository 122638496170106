@for (verticalLine of tableData.lines.vertical; track verticalIndex; let verticalIndex = $index) {
  @for (
    horizontalLine of tableData.lines.horizontal;
    track horizontalIndex;
    let horizontalIndex = $index
  ) {
    <div
      class="absolute z-50 h-2 w-2"
      [ngClass]="verticalIndex !== horizontalIndex ? 'cursor-nesw-resize' : 'cursor-nwse-resize'"
      [style.left.px]="getTableXInPixels(verticalLine.offset) - 3"
      [style.top.px]="getTableYInPixels(horizontalLine.offset) - 3"
      (mouseenter)="setArePreviewLinesEnabled.emit(false)"
      (mouseleave)="setArePreviewLinesEnabled.emit(true)"
      (mousedown)="
        mouseDown.emit({
          verticalIndex,
          horizontalIndex,
          horizontalLine,
          verticalLine,
        });
        $event.stopPropagation()
      "
    ></div>
  }
}
