<p-confirmDialog #editDialog key="editDialog-{{ tableData.pageId }}-{{ tableData.id }}">
  <ng-template pTemplate="headless">
    <app-dialog
      acceptText="Bestätigen"
      closeText="Abbrechen"
      title="Manuelle Änderung verwerfen"
      (acceptDialog)="acceptedDialog.emit(); editDialog.accept()"
      (closeDialog)="editDialog.reject()"
    >
      <p class="p-6">
        Wenn Du das Layout der Tabelle veränderst gehen deine manuellen Änderungen in der Tabelle
        verloren.
      </p>
    </app-dialog>
  </ng-template>
</p-confirmDialog>
