import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { CtDocument, CtPage } from '../../../models/ct-batch-model';
import { ImageListPreviewItemDocumentComponent } from './image-list-preview-item-document/image-list-preview-item-document.component';
import { ImageListPreviewItemPageComponent } from './image-list-preview-item-page/image-list-preview-item-page.component';
import { MatIcon } from '@angular/material/icon';
import { NgClass } from '@angular/common';

@Component({
  selector: 'app-image-list-preview',
  standalone: true,
  imports: [
    ImageListPreviewItemDocumentComponent,
    ImageListPreviewItemPageComponent,
    MatIcon,
    NgClass,
  ],
  templateUrl: './image-list-preview.component.html',
})
export class ImageListPreviewComponent implements OnInit {
  _currentPageIndex = 0;

  @Input({ required: true }) set currentPageIndex(index: number) {
    this._currentPageIndex = index;
    // TODO: I would argue this is not a "subject" discussion but a technical flag...
    if (!this.isFieldExtractionView) {
      this.scrollToChildAtIndex(index);
    }
  }

  get currentPageIndex() {
    return this._currentPageIndex;
  }

  protected _selectedDocumentId!: string;

  @Input({ required: true }) set selectedDocumentId(id: string) {
    this._selectedDocumentId = id;
    this.scrollToChildAtIndex(this.documents.findIndex((document) => document.id === id));
  }

  get selectedDocumentId() {
    return this._selectedDocumentId;
  }

  @Input({ required: true }) documents!: CtDocument[];
  @Input({ required: true }) isFieldExtractionView!: boolean;
  @Input({ required: true }) isPreviewListExpandedOrHovered!: boolean;

  @Output() scrollToIndex = new EventEmitter<number>();

  @ViewChild('pagePreviewList') pagePreviewList?: ElementRef<HTMLDivElement>;

  private scrollToChildAtIndex(index: number) {
    if (!this.pagePreviewList) return;
    const child = this.pagePreviewList.nativeElement.children[index];
    child.scrollIntoView({
      behavior: 'instant',
      block: 'center',
    });
  }

  ngOnInit(): void {
    this.selectedDocumentId = this.documents[0].id;
  }

  typeThumbnail(documentClass: string): CtPage {
    const filteredDocuments = this.documents.filter((document) => {
      return document.documentClass === documentClass;
    });
    return filteredDocuments[0]?.pages[0];
  }

  scrollToFirstPageOfDocument(documentIndex: number) {
    let pageIndex = 0;
    for (let i = 0; i < documentIndex; i++) {
      pageIndex += this.documents[i].pages.length;
    }

    this.scrollToPage(pageIndex);
  }

  scrollToPage($index: number) {
    this.scrollToIndex.emit($index);
  }
}
