import { transformDate } from '../pipes/ocr-date.pipe';
import { minBy } from '../utils/Array.utils';
import { ExtractedFields } from '../models/interfaces';
import { BuildSimpleExtractionResultDto } from './build-simple-model';

export const getBuildSimpleExtractedFields = (
  extractionDataByDocumentId: Record<string, BuildSimpleExtractionResultDto>,
): ExtractedFields => {
  return {
    ClaimantBirthDate: getFieldWithHighestConfidenceFromAllDocuments(
      extractionDataByDocumentId,
      'kopfdaten_geburtsdatum',
      (value: string) => transformDate(value).toDate(),
    ),
    InvoiceDate: getFieldWithHighestConfidenceFromAllDocuments(
      extractionDataByDocumentId,
      'kopfdaten_forderungsdatum',
      (value: string) => transformDate(value).toDate(),
    ),
    KstSign: getFieldWithHighestConfidenceFromAllDocuments(
      extractionDataByDocumentId,
      'kopfdaten_kostentraegerzeichen',
    ),
  };
};

export const getFieldWithHighestConfidenceFromAllDocuments = <T>(
  extractionDataByDocumentId: Record<string, BuildSimpleExtractionResultDto>,
  extractedFieldKey: string,
  transform: (value: string) => T = (x) => x as T,
) => {
  const allFieldsForKey = Object.values(extractionDataByDocumentId).flatMap(
    (extractionData) => extractionData.entities[extractedFieldKey] ?? [],
  );

  const highestConfidenceResult = minBy(allFieldsForKey, (field) => field.confidence);
  if (!highestConfidenceResult) {
    return undefined;
  }

  return transform(highestConfidenceResult.value);
};
