/**
 * Returns a function to parse two digit dates as follows:
 *
 * For a given two digit date `X` we use the current year `Y`
 * as the cutoff year to produce dates `X'` with `X' ≤ Y` per default.
 *
 * In other words, entering a two digit year should never result in a
 * future year.
 *
 * Examples:
 * Examples:
 *
 * <ul>
 *   <li>Current year `2025`, `input = a.b.23 ⇒ result = a.b.2023` (because `23 ≤ 25`)</li>
 *   <li>Current year `2048`, `input = a.b.55 ⇒ result = a.b.1955` (because `55 > 48`)</li>
 *   <li>Current year `2000`, `input = a.b.00 ⇒ result = a.b.2000` (because `0 ≤ 0`)</li>
 * </ul>
 */
const twoDigitDateParseFunction = yearString => {
  const currentYear = new Date().getFullYear();
  return parseInt(yearString) + (parseInt(yearString) > currentYear - 2000 ? 1900 : 2000);
};

/**
 * Generated bundle index. Do not edit.
 */

export { twoDigitDateParseFunction };
