import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthenticationService } from '../services/authentication.service';

export const keycloakGuard = async (
  _: ActivatedRouteSnapshot,
  state: RouterStateSnapshot,
): Promise<boolean> => {
  const authenticationService = inject(AuthenticationService);

  return new Promise((resolve, _) => {
    if (!authenticationService.isLoggedIn()) {
      resolve(authenticationService.login(window.location.origin + state.url).then(() => false));
    }

    resolve(true);
  });
};
