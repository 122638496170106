<div
  class="absolute"
  [ngClass]="[
    line.isBoundary ? 'border-0' : 'border border-dashed',
    line.orientation === LineOrientation.HORIZONTAL
      ? 'w-full border-primary'
      : 'h-full border-[#72c8e5]',
  ]"
  [ngStyle]="getStretchStyle()"
>
  @if (!hideEventtarget) {
    <!-- event target -->
    <div
      class="group pointer-events-auto absolute z-40 cursor-col-resize"
      [style]="
        line.orientation === LineOrientation.HORIZONTAL
          ? { width: 'calc(100% + 2rem)' }
          : { height: 'calc(100% + 2rem)' }
      "
      [ngClass]="
        line.orientation === LineOrientation.HORIZONTAL
          ? '-top-1.5 right-0 h-2 cursor-row-resize'
          : '-left-1 bottom-0 w-2 cursor-col-resize'
      "
      (click)="$event.stopPropagation()"
      (mousedown)="$event.stopPropagation(); $event.preventDefault(); selected.emit()"
      (contextmenu)="$event.preventDefault()"
      (dblclick)="isDeletable && delete.emit()"
    >
      @if (isDeletable) {
        <button
          (click)="delete.emit()"
          (mouseup)="$event.stopPropagation()"
          [ngClass]="getButtonStyleClasses()"
          class="absolute z-10 hidden h-6 w-6 rounded bg-[#A50000] group-hover:block"
        >
          <mat-icon [ngStyle]="{ color: 'white' }">close</mat-icon>
        </button>
      }
    </div>
  }
</div>
