import { Component, EventEmitter, Output } from '@angular/core';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { LocalizedDatePipe } from '../../../pipes/localized-date.pipe';
import { MatIcon } from '@angular/material/icon';
import { SharedModule } from 'primeng/api';
import { NgStyle } from '@angular/common';
import { DialogComponent } from '../dialog/dialog.component';

@Component({
  selector: 'app-discard-table-dialog',
  standalone: true,
  imports: [
    ConfirmDialogModule,
    LocalizedDatePipe,
    MatIcon,
    SharedModule,
    NgStyle,
    DialogComponent,
  ],
  templateUrl: './discard-table-dialog.component.html',
})
export class DiscardTableDialogComponent {
  @Output() acceptedDialog = new EventEmitter<void>();
}
