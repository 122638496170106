<div class="flex w-full cursor-pointer flex-col justify-center gap-2 pt-2">
  <div class="flex w-full items-center justify-center">
    <div class="relative z-[3]">
      @if (pageImageService.isImageLoaded(typeThumbnail.id)) {
        <img
          [src]="typeThumbnail.id | loadImage | async"
          [alt]="typeThumbnail"
          loading="lazy"
          class="relative z-[3] h-full rounded-md border-[3px] bg-white object-contain"
          [ngClass]="selected ? 'border-theme-blue' : 'border-theme-gray-400'"
        />
      } @else {
        <mat-spinner class="relative z-[3] h-full w-full scale-50 object-contain"></mat-spinner>
      }
      <div
        class="absolute -top-1 left-1 z-[2] h-full w-full rounded-md border-[3px] border-theme-gray-400 bg-white px-1 opacity-60"
      ></div>
      <div
        class="absolute -top-2 left-2 z-[1] h-full w-full rounded-md border-[3px] border-theme-gray-400 bg-white px-1 opacity-30"
      ></div>
    </div>
  </div>
  <div
    class="flex max-w-full flex-row items-center justify-center gap-1.5 text-wrap rounded-[9px] p-1.5"
    [ngClass]="selected ? 'bg-theme-blue' : 'bg-theme-gray-400'"
  >
    <img
      loading="lazy"
      [src]="'assets/icons/document-class/' + documentType + '.svg'"
      [ngClass]="selected ? 'brightness-[100]' : ''"
      [alt]="documentType"
    />
    <span
      class="h-fit overflow-hidden text-ellipsis text-center text-xxs"
      [ngClass]="selected ? 'font-bold text-white' : 'text-black'"
      >{{ documentType }}</span
    >
  </div>
</div>
