<button
  class="disabled: group h-7 w-7 cursor-pointer rounded-md align-middle transition-all duration-300 enabled:hover:bg-[#E9F4F8] disabled:cursor-default disabled:grayscale"
  (click)="buttonClick.emit()"
  [ngClass]="additionalClasses"
  [disabled]="disabled"
>
  <img
    [src]="'assets/icons/' + iconName + '.svg'"
    [alt]="iconName"
    loading="lazy"
    class="h-full w-full transition-all duration-300 group-hover:invert-[80%] group-hover:filter group-disabled:invert-[40%]"
    [ngStyle]="{
      filter: isActive
        ? 'invert(44%) sepia(77%) saturate(351%) hue-rotate(152deg) brightness(90%) contrast(86%)'
        : '',
    }"
  />
</button>
