import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NgClass } from '@angular/common';
import { remap, TableData, TableOverlayLine, WordRect } from '../../../../../models/interfaces';

@Component({
  selector: 'app-table-overlay-move-intersection',
  standalone: true,
  imports: [NgClass],
  templateUrl: './table-overlay-move-intersection.component.html',
})
export class TableOverlayMoveIntersectionComponent {
  @Input({ required: true }) tableData!: TableData;
  @Input({ required: true }) tableRect!: WordRect;
  @Input({ required: true }) tableRef!: HTMLDivElement;

  @Output() setArePreviewLinesEnabled = new EventEmitter<boolean>();
  @Output() mouseDown = new EventEmitter<{
    horizontalLine: TableOverlayLine;
    horizontalIndex: number;
    verticalLine: TableOverlayLine;
    verticalIndex: number;
  }>();

  getTableYInPixels(relativeOverlayY: number): number {
    return (
      remap(relativeOverlayY, this.tableRect.y[0], this.tableRect.y[1], 0, 1) *
      this.tableRef.offsetHeight
    );
  }

  getTableXInPixels(relativeOverlayX: number): number {
    return (
      remap(relativeOverlayX, this.tableRect.x[0], this.tableRect.x[1], 0, 1) *
      this.tableRef.offsetWidth
    );
  }
}
