import { Directive, ElementRef, HostListener, Signal, signal, WritableSignal } from '@angular/core';
import { Vector2, WordRect } from '../models/interfaces';
import { Observable, Subject } from 'rxjs';

@Directive({
  selector: '[appLassoSelection]',
  standalone: true,
})
export class LassoSelectionDirective {
  private lastMouseDown?: Vector2 = undefined;
  private _currentNormalizedMousePosition: WritableSignal<Vector2> = signal([0, 0]);
  private _currentLasso: WritableSignal<WordRect | undefined> = signal(undefined);
  private _lastReleasedLasso$: Subject<WordRect> = new Subject<WordRect>();

  private hostElement: HTMLElement;

  constructor(hostElement: ElementRef<HTMLElement>) {
    this.hostElement = hostElement.nativeElement;
  }

  @HostListener('mousedown', ['$event']) onMouseDown(event: MouseEvent) {
    this.lastMouseDown = this.getNormalizedMousePosition(event);
    this.updateLasso(this.lastMouseDown);
  }

  @HostListener('mousemove', ['$event']) onMouseMove(event: MouseEvent) {
    const normalizedMousePosition = this.getNormalizedMousePosition(event);
    this._currentNormalizedMousePosition.set(normalizedMousePosition);
    this.updateLasso(normalizedMousePosition);
  }

  @HostListener('mouseup', ['$event']) onMouseUp() {
    const lasso = this.currentLasso();
    if (lasso) {
      this._lastReleasedLasso$.next(lasso);
    }

    this._currentLasso.set(undefined);
    this.lastMouseDown = undefined;
  }

  get currentLasso(): Signal<WordRect | undefined> {
    return this._currentLasso.asReadonly();
  }

  get currentMousePosition(): Signal<Vector2> {
    return this._currentNormalizedMousePosition.asReadonly();
  }

  get lastReleasedLasso$(): Observable<WordRect> {
    return this._lastReleasedLasso$.asObservable();
  }

  private isDragging(): boolean {
    return this.lastMouseDown !== undefined;
  }

  private updateLasso(normalizedMousePosition: Vector2) {
    if (!this.isDragging()) return;

    this._currentLasso.set(
      this.fixRectOrientation({
        x: [this.lastMouseDown![0], normalizedMousePosition[0]],
        y: [this.lastMouseDown![1], normalizedMousePosition[1]],
      }),
    );
  }

  private fixRectOrientation(rect: WordRect): WordRect {
    const x1 = rect.x[0];
    const x2 = rect.x[1];
    const y1 = rect.y[0];
    const y2 = rect.y[1];
    return {
      x: x1 <= x2 ? [x1, x2] : [x2, x1],
      y: y1 <= y2 ? [y1, y2] : [y2, y1],
    };
  }

  private divide(v1: Vector2, v2: Vector2): Vector2 {
    return [v1[0] / v2[0], v1[1] / v2[1]];
  }

  private getNormalizedMousePosition(event: MouseEvent): Vector2 {
    const targetDimensions: Vector2 = [this.hostElement.offsetWidth, this.hostElement.offsetHeight];

    const boundingRect = this.hostElement.getBoundingClientRect();

    const mousePosition: Vector2 = [event.clientX - boundingRect.x, event.clientY - boundingRect.y];
    return this.divide(mousePosition, targetDimensions);
  }
}
