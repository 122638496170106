import { ValidatorFn } from '@angular/forms';

export interface Config {
  apiBaseUrl: string;
  keycloakUrl: string;
  auditUrl: string;
  psBackend: string;
  tableDetectionUrl: string;
  ignoreBuildSimpleExtractionResults?: boolean;
}

export type Vector2 = readonly [number, number];
export type Interval = Vector2;

export interface WordRect {
  readonly x: Interval;
  readonly y: Interval;
}

export const pointRect = (point: Vector2): WordRect => ({
  x: [point[0], point[0]],
  y: [point[1], point[1]],
});

export interface Dimension2d {
  width: number;
  height: number;
}

export interface AuditPosition {
  description?: string;
  from?: Date;
  to?: Date;
  originalAmount?: number;
  tableId: string;
  pageId: string;
}

export interface TableField {
  validationError?: string;
}

export interface TableDescriptionField extends TableField {
  value?: string;
}

export interface TableDateField extends TableField {
  value?: Date;
  valueAsString?: string;
}

export interface TableNumberField extends TableField {
  value?: number;
}

export interface TableRow {
  description: TableDescriptionField;
  from: TableDateField;
  to: TableDateField;
  amount: TableNumberField;
  tableId?: string;
  pageId?: string;
}

export type FieldType = 'string' | 'date';

export interface FieldModel {
  key: string;
  label: string;
  type: FieldType;
  validations: ValidatorFn[];
}

export const fieldModels: FieldModel[] = [
  {
    key: 'InvoiceDate',
    label: 'Forderungsdatum',
    type: 'date',
    validations: [],
  },
  {
    key: 'ClaimantBirthDate',
    label: 'Geburtsdatum geschädigte Person',
    type: 'date',
    validations: [],
  },
  {
    key: 'KstSign',
    label: 'Zeichen Kostenträger',
    type: 'string',
    validations: [],
  },
];

export enum LineOrientation {
  VERTICAL = 'vertical',
  HORIZONTAL = 'horizontal',
}

export enum Key {
  CTRL = 'Control',
  ALT = 'Alt',
  ESCAPE = 'Escape',
}

export interface TableOverlayLine {
  orientation: LineOrientation;
  offset: number;
  isBoundary?: boolean;
}

export const remap = (x: number, min1: number, max1: number, min2: number, max2: number) =>
  min2 + ((max2 - min2) * (x - min1)) / (max1 - min1);

export interface TableData {
  lasso: WordRect;
  confirmed: boolean;
  layoutDefined: boolean;
  lines: {
    vertical: TableOverlayLine[];
    /**
     * offset 0 is top of page, 1 is bottom
     */
    horizontal: TableOverlayLine[];
  };
  columnHeaders: (string | undefined)[];
  id: string;
  pageId: string;
}

export enum FitType {
  WIDTH,
  HEIGHT,
}

export const tableHeaderConstants = {
  Beschreibung: 'Beschreibung',
  Von: 'Von',
  Bis: 'Bis',
  Betrag: 'Betrag',
};
export const tableHeaders = Object.values(tableHeaderConstants);
export type CurrentColumnKeys = 'Beschreibung' | 'Von' | 'Bis' | 'Betrag';

export const NOT_IMPORTANT_TABLE_HEADER = 'Unwichtig';

export enum AppView {
  FIELD_EXTRACTION = 'FIELD_EXTRACTION',
  TABLE_EXTRACTION = 'TABLE_EXTRACTION',
}

export interface ButtonState {
  label: string;
  isActive: boolean;
  action: () => void;
}

export interface AuditPatchDto {
  invoiceDate?: string;
  birthDate?: string;
  kstSign?: string;
  positions: AuditPositionDto[];
}

export interface AuditPositionDto {
  description: string;
  from: string;
  to: string;
  originalAmount: number;
}

export interface ExtractedFields {
  InvoiceDate?: Date;
  ClaimantBirthDate?: Date;
  KstSign?: string;
}

export type CellIndices = `${number}|${number}`; // row|column

export interface Intersection {
  indices: CellIndices;
  row: number;
  column: number;
  xShare: number;
  share: number;
}

/**
 * Represents a "cut" of a word belonging only partially to a data-capturing-table cell.
 */
export interface Cut {
  /**
   * The data-capturing-table cell this cuts belongs to
   */
  indices: CellIndices;

  /**
   * The cutout of the word as a relative interval (e.g. [40%, 90%])
   */
  interval: Interval;
}
