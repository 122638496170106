import { inject, Injectable } from '@angular/core';
import { AuditPosition, ExtractedFields, TableRow } from '../../models/interfaces';
import { PersistDataService } from '../app-state/persist-data.service';

@Injectable({
  providedIn: 'root',
})
export class ExtractedDataStoreService {
  private persistDataService = inject(PersistDataService);
  private _extractedFields: ExtractedFields = {} as ExtractedFields;
  private auditPositions: AuditPosition[] = [];

  constructor() {
    if (this.persistDataService.auditPositions)
      this.auditPositions = this.persistDataService.auditPositions;
    if (this.persistDataService.extractedFields)
      this.extractedFields = this.persistDataService.extractedFields;
  }

  set extractedFields(extractedFields: ExtractedFields) {
    this._extractedFields = extractedFields;
    this.persistDataService.extractedFields = this.extractedFields;
  }

  get extractedFields() {
    return this._extractedFields;
  }

  setAuditPositionArray(tableRows: TableRow[]) {
    const auditPositions: AuditPosition[] = [];
    tableRows.forEach((row) => {
      const obj: AuditPosition = {
        description: row.description.value,
        from: row.from.value,
        to: row.to.value,
        originalAmount: row.amount.value,
        tableId: row.tableId ?? 'temporary_id',
        pageId: row.pageId ?? '',
      };

      auditPositions.push(obj);
    });

    this.auditPositions = [...auditPositions];
    this.persistDataService.auditPositions = this.auditPositions;
  }

  get auditPositionArray() {
    return this.auditPositions;
  }
}
