import { Component, Input } from '@angular/core';
import { CtBatch } from '../../../../models/ct-batch-model';

@Component({
  standalone: true,
  selector: 'app-ct-batch-details',
  templateUrl: './ct-batch-details.component.html',
})
export class CtBatchDetailsComponent {
  @Input({ required: true }) ctBatch!: CtBatch;
}
