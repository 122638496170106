<div
  (waResizeObserver)="updateDimensions()"
  class="absolute bottom-0 left-0 right-0 top-0"
  [ngClass]="{
    'cursor-crosshair': isTableCreationToggled && !isFieldExtractionView,
  }"
></div>

@if (isDimensionSet()) {
  @if (!isTableCreationToggled || isFieldExtractionView) {
    <app-text-selection-overlay
      [selectedWordsRect]="selectedWordRects()"
      [lasso]="boxSelection.currentLasso()"
      [pageId]="page.id"
    />
  }

  @if (!isFieldExtractionView) {
    @for (entry of tableCollection; track entry.id) {
      <app-table-overlay
        [page]="page"
        [isTableCreationToggled]="isTableCreationToggled"
        [tableData]="entry"
        (deleteTable)="deleteTable($event)"
      />
    }

    @if (isTableCreationToggled) {
      <app-table-creation-overlay [pageId]="page.id" [currentLasso]="boxSelection.currentLasso()" />
    }
  }
}
