import { Pipe, PipeTransform } from '@angular/core';
import dayjs from 'dayjs';

@Pipe({
  name: 'localizedDate',
  standalone: true,
})
export class LocalizedDatePipe implements PipeTransform {
  transform(value: string | Date): string {
    return dayjs(value).format('DD.MM.YYYY');
  }
}
