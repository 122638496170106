<div class="flex h-full w-full flex-col items-center gap-y-4 overflow-y-auto p-4">
  <div class="w-full" (waMutationObserver)="tableElementsChanged.emit()" childList #table>
    <div class="flex gap-2 rounded-t-lg">
      @for (header of tableHeader; track header) {
        @if (header !== "id") {
          <span class="text-left font-bold" [ngClass]="getWidthForInput(header)">{{ header }}</span>
        }
      }
      <span class="min-w-[24px]"></span>
    </div>
    @for (row of tableRows; track row; let rowIndex = $index) {
      <app-data-capturing-table-item
        [row]="row"
        [rowIndex]="rowIndex"
        (addRowAtIndex)="rowAdded.emit($event)"
        (deleteRowAtIndex)="rowDeleted.emit($event)"
        (newCurrentFieldIndex)="updateCurrentFieldIndex.emit($event)"
        (focusOutInput)="setRowData.emit($event)"
        [currentFieldIndex]="currentFieldIndex"
        [topAndBottomAddLineControls]="rowIndex === tableRows.length - 1"
      />
    }
  </div>
</div>
