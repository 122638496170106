import {
  Component,
  computed,
  EventEmitter,
  HostListener,
  inject,
  OnInit,
  Output,
  signal,
  ViewChild,
  WritableSignal,
} from '@angular/core';
import { ExtractedTableCreationService } from '../../../services/extracted-data/extracted-table-creation.service';
import { DataCapturingTableComponent } from './data-capturing-table/data-capturing-table.component';
import { ToolbarService } from '../../../services/app-state/toolbar.service';
import { NgClass } from '@angular/common';
import {
  CurrentColumnKeys,
  TableDateField,
  TableDescriptionField,
  tableHeaderConstants,
  TableNumberField,
  TableRow,
} from '../../../models/interfaces';
import { OcrSelectionService } from '../../../services/ocr/ocr-selection.service';
import { ConfirmService } from '@l21s-ecnps/gui-commons';
import { ExtractedTableRemovalService } from '../../../services/extracted-data/extracted-table-removal.service';
import { ExtractedDataStoreService } from '../../../services/extracted-data/extracted-data-store.service';
import { ButtonStateService } from '../../../services/app-state/button-state.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { sanitizeDateInput } from '../../../utils/SanitizingInput.utils';
import { parseCurrency } from '../../../utils/ParseCurrency.utils';
import { FormsModule } from '@angular/forms';
import { InputNumberModule } from 'primeng/inputnumber';
import { MatIcon } from '@angular/material/icon';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { DialogComponent } from '../../dialogs/dialog/dialog.component';
import { ConfirmationService } from 'primeng/api';
import { getInputClasses } from '../../../utils/InputValidation.utils';
import { MatTooltip } from '@angular/material/tooltip';
import { formatMoney } from '../../../utils/FormatMoney.utils';
import { transformDate } from '../../../pipes/ocr-date.pipe';

@UntilDestroy()
@Component({
  selector: 'app-data-capturing-table-view',
  standalone: true,
  templateUrl: './data-capturing-table-view.component.html',
  imports: [
    DataCapturingTableComponent,
    NgClass,
    FormsModule,
    InputNumberModule,
    MatIcon,
    ConfirmDialogModule,
    DialogComponent,
    MatTooltip,
  ],
})
export class DataCapturingTableViewComponent implements OnInit {
  private buttonStateService = inject(ButtonStateService); //
  private extractedTableRemovalService = inject(ExtractedTableRemovalService); //
  private extractedTableCreationService = inject(ExtractedTableCreationService); //
  private toolbarService = inject(ToolbarService); //
  private ocrSelectionService = inject(OcrSelectionService); //
  private confirmService = inject(ConfirmService); //
  private extractedDataStoreService = inject(ExtractedDataStoreService); //
  private confirmationService = inject(ConfirmationService);

  @Output() openNextDocumentEmitter = new EventEmitter();

  @ViewChild('dataCapturingTableComponent')
  dataCapturingTableComponent?: DataCapturingTableComponent;

  tableRows: TableRow[] = [];
  currentFieldIndex: WritableSignal<number | undefined> = signal(0);
  buildTable!: boolean;

  // TODO: extract this somehow..
  protected suggestedSumOfPositions: WritableSignal<{
    value?: string | number;
    validationError?: string;
  }> = signal({
    value: undefined,
    validationError: undefined,
  });
  protected calculatedSumOfPositions: WritableSignal<number> = signal(0);
  protected areSumsDiverging = computed(
    () =>
      this.suggestedSumOfPositions().value !== undefined &&
      this.suggestedSumOfPositions().validationError === undefined &&
      this.suggestedSumOfPositions().value !== this.calculatedSumOfPositions(),
  );

  protected suggestedSumInputActive = false;

  // TODO: Refactor window:* key handling
  private isCtrlPressed = false;

  ngOnInit() {
    this.loadCachedPositions();

    this.buttonStateService.setNextButtonState({
      label: 'Weiter',
      isActive: true,
      action: () => {
        this.openNextDocument();
      },
    });

    this.extractedTableCreationService.createdTable$
      .pipe(untilDestroyed(this))
      .subscribe((tableRows) => {
        this.buildTable = true;
        // TODO: this is obviously only temporary and has to be changed when the columns are labelled
        // TODO: validation
        if (tableRows.length > 0) {
          this.updateTableRows([
            ...this.tableRows,
            ...tableRows.map((row) => {
              return {
                description: row.description,
                from: row.from,
                to: row.to,
                amount: row.amount,
                tableId: row.tableId,
                pageId: row.pageId,
              };
            }),
          ]);

          this.storeValues();
        }
      });

    this.ocrSelectionService.selectedWords$.pipe(untilDestroyed(this)).subscribe((words) => {
      if (this.toolbarService.getTableCreationToggled()) return;
      const joinedString = words.join(' ');

      // TODO: handle this in a more general way to allow arbitrary non-table-inputs to receive ocr selection data
      const currentTableFieldIndex = this.currentFieldIndex();
      if (currentTableFieldIndex !== undefined) {
        const currInput = this.getInputElement(currentTableFieldIndex)!;

        const index = currInput.dataset['row'] as unknown as number;
        const headerKey = currInput.dataset['header'] as CurrentColumnKeys;

        this.handleTableHeaders(headerKey, joinedString, index);
      } else if (this.suggestedSumInputActive) {
        this.updateSuggestedSum(joinedString);
        this.suggestedSumInputActive = false;
      }
    });

    this.extractedTableRemovalService.destroyedTable$
      .pipe(untilDestroyed(this))
      .subscribe(({ tableId, pageId }) => {
        this.removeMatchingTableRows(tableId, pageId);
      });

    this.buildTable = this.extractedDataStoreService.auditPositionArray.length > 0;
  }

  @HostListener('window:keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    if (event.key === 'Control') {
      this.isCtrlPressed = true;
    }

    if (event.code === 'Enter') {
      this.setNextField();
    }
  }

  @HostListener('window:keyup', ['$event'])
  onKeyUp(event: KeyboardEvent) {
    if (event.key === 'Control') {
      this.isCtrlPressed = false;
    }
  }

  emptyRow = (tableId: string | undefined, pageId: string | undefined): TableRow => {
    return {
      description: {},
      from: {},
      to: {},
      amount: {},
      tableId: tableId,
      pageId: pageId,
    };
  };

  startManualCreation() {
    this.buildTable = true;
    this.toolbarService.setTableCreationToggled(false);
    this.updateTableRows([...this.tableRows, structuredClone(this.emptyRow('temporary_id', ''))]);
  }

  removeMatchingTableRows(tableId: string, pageId: string): void {
    this.updateTableRows(
      this.tableRows.filter(
        (row) => row.pageId !== pageId || (row.pageId === pageId && row['tableId'] !== tableId),
      ),
    );
    this.storeValues();
  }

  storeValues() {
    this.extractedDataStoreService.setAuditPositionArray(this.getNotEmptyTableRows());
  }

  private getInputElement(index: number): HTMLInputElement | undefined {
    // TODO: select nth - child?
    return this.dataCapturingTableComponent?.getTableElement().querySelectorAll('input')[index];
  }

  private loadCachedPositions(): void {
    const auditPositions = this.extractedDataStoreService.auditPositionArray;
    if (auditPositions.length <= 0) {
      return;
    }
    this.updateTableRows(
      auditPositions.map((auditPosition) => ({
        description: {
          value: auditPosition.description,
        },
        from: {
          value: auditPosition.from,
        },
        to: {
          value: auditPosition.to,
        },
        amount: {
          value: auditPosition.originalAmount,
        },
        tableId: auditPosition.tableId,
        pageId: auditPosition.pageId,
      })),
    );

    this.applyValidation();
  }

  private applyValidation() {
    this.tableRows.map((row) => {
      if (!row.description.value)
        row.description.validationError = 'Der Wert ist keine gültige Beschreibung';
      if (!row.from.value) row.from.validationError = 'Der Wert ist kein gültiges Datum';
      if (!row.amount.value) row.amount.validationError = 'Der Wert ist kein gültiger Betrag';
    });
  }

  protected activateTableFieldAtIndex(index: number) {
    this.currentFieldIndex.set(index);
    this.suggestedSumInputActive = false;
  }

  addRowAtIndex(
    index: number,
    tableId: string | undefined = 'temporary_id',
    pageId: string | undefined = '',
  ): void {
    const { newTableId, newPageId } = this.determineTableBelonging(index, tableId, pageId);
    this.updateTableRows(
      this.tableRows.toSpliced(index, 0, structuredClone(this.emptyRow(newTableId, newPageId))),
    );

    this.storeValues();
  }

  private determineTableBelonging(
    rowIndex: number,
    tableId: string | undefined,
    pageId: string | undefined,
  ) {
    const previousRow: TableRow | undefined = this.tableRows[rowIndex - 1] as TableRow | undefined;

    if (!previousRow?.pageId) {
      return { newTableId: 'temporary_id', newPageId: '' };
    }

    if (previousRow.pageId !== pageId || previousRow.tableId !== tableId) {
      return { newTableId: 'temporary_id', newPageId: '' };
    }
    return { newTableId: tableId, newPageId: pageId };
  }

  removeRowAtIndex(index: number): void {
    this.updateTableRows(this.tableRows.toSpliced(index, 1));
    this.storeValues();

    if (this.tableRows.length === 0) {
      this.buildTable = false;
    }
  }

  setNextField() {
    if (this.currentFieldIndex() === undefined) return;
    this.getInputElement(this.currentFieldIndex()!)!.blur();

    const nextFieldIndex = this.getNextFieldIndex();

    const noNextField = nextFieldIndex === -1;
    if (noNextField) {
      this.addRowAtIndex(this.tableRows.length);
      this.activateTableFieldAtIndex(this.getNextFieldIndex()!);

      return;
    }

    this.activateTableFieldAtIndex(nextFieldIndex!);
    this.getInputElement(this.currentFieldIndex()!)!.focus();
  }

  getNextFieldIndex(): number | undefined {
    if (this.currentFieldIndex() === undefined) return undefined;

    const nextFieldIndex = this.getFieldArray(this.tableRows)
      .slice(this.currentFieldIndex()! + 1)
      .findIndex((field) => {
        return !field.value || field.validationError;
      });

    if (nextFieldIndex === -1) return nextFieldIndex;

    return nextFieldIndex + this.currentFieldIndex()! + 1;
  }

  openNextDocument() {
    const filteredFields = this.getFieldArray(this.getNotEmptyTableRows());
    if (filteredFields.some((field) => !field.value && field.validationError)) {
      this.confirmService.snackError('Füllen Sie alle Felder korrekt aus.');
      return;
    }

    if (this.areSumsDiverging()) {
      this.confirmationService.confirm({
        accept: () => {
          this.openNextDocumentEmitter.emit();
        },
      });
    } else {
      this.openNextDocumentEmitter.emit();
    }
  }

  getNotEmptyTableRows() {
    return this.tableRows.filter((row) => {
      let isEmpty = true;

      if (row.amount.value !== undefined) {
        isEmpty = false;
      }
      if (row.from.value !== undefined) {
        isEmpty = false;
      }
      if (row.to.value !== undefined) {
        isEmpty = false;
      }
      if (row.description.value !== undefined && row.description.value !== '') {
        isEmpty = false;
      }
      return !isEmpty;
    });
  }

  onTableChange(): void {
    if (this.currentFieldIndex() === undefined) return;

    const element = this.getInputElement(this.currentFieldIndex()!);
    if (!element) return;

    element.focus();
    element.setSelectionRange(0, 0);
    element.scrollLeft = 0;
  }

  private getFieldArray(
    tableRows: TableRow[],
  ): (TableDescriptionField | TableDateField | TableNumberField)[] {
    return tableRows.flatMap((row) => {
      const consideredProperties = {
        description: row.description,
        from: row.from,
        to: row.to,
        amount: row.amount,
      };
      return Object.values(consideredProperties);
    });
  }

  setRowDataAtIndex(event: Event, index: number): void {
    const input = event.target as HTMLInputElement;
    const headerKey = input.dataset['header'] as CurrentColumnKeys;

    this.handleTableHeaders(headerKey, input.value, index);
    this.recalculateSumOfPositions();
  }

  handleTableHeaders(headerKey: string, value: string, index: number) {
    switch (headerKey) {
      case tableHeaderConstants.Von:
      case tableHeaderConstants.Bis:
        this.handleDateField(value, index, headerKey);
        break;
      case tableHeaderConstants.Betrag:
        this.handleBetragField(value, index);
        break;
      default:
        this.handleDefaultField(value, index);
    }
    this.storeValues();
  }

  private handleDateField(joinedString: string, index: number, headerKey: string) {
    const sanitizedString = sanitizeDateInput(joinedString);
    const date = transformDate(sanitizedString);
    let newDateValue: Date | undefined = date.toDate();
    let validationError = undefined;
    if (!date.isValid()) {
      if (sanitizedString !== '') {
        this.confirmService.snackError(`Der Wert "${sanitizedString}" ist kein gültiges Datum`);
      }
      newDateValue = undefined;
      validationError = 'Der Wert ist kein gültiges Datum';
    }

    switch (headerKey) {
      case tableHeaderConstants.Von:
        this.tableRows[index].from = {
          value: newDateValue,
          validationError: validationError,
        };
        break;
      case tableHeaderConstants.Bis:
        this.tableRows[index].to = {
          value: newDateValue,
          validationError: sanitizedString ? validationError : undefined,
        };
        break;
    }
  }

  private updateTableRows(tableRows: TableRow[]) {
    this.tableRows = tableRows;
    this.recalculateSumOfPositions();

    if (this.tableRows.length === 0) {
      this.buildTable = false;
    }
  }

  private handleBetragField(joinedString: string, index: number) {
    // TODO@Lucas: Why is parsing / validation not equivalent?
    const parsedNumber = parseCurrency(joinedString);
    const isInvalid = isNaN(parseFloat(joinedString));
    this.tableRows[index].amount = {
      value: joinedString === '' ? undefined : parsedNumber,
      validationError: isInvalid ? 'Der Wert ist kein gültiger Betrag' : undefined,
    };
  }

  private handleDefaultField(joinedString: string, index: number) {
    const currentValue = this.tableRows[index].description.value;
    const newValue =
      currentValue && this.isCtrlPressed ? currentValue + ' ' + joinedString : joinedString;
    const isEmpty = newValue.length === 0;
    this.tableRows[index].description = {
      value: isEmpty ? undefined : newValue,
      validationError: isEmpty ? 'Der Wert ist keine gültige Beschreibung' : undefined,
    };
  }

  protected recalculateSumOfPositions() {
    this.calculatedSumOfPositions.set(
      // TODO: Maybe we should store all money values in cents to avoid rounding issues here
      Number(
        (
          Math.round(
            this.tableRows
              .map((row) => (row.amount.validationError ? 0 : (row.amount.value ?? 0)))
              .reduce((sum, value) => sum + value, 0) * 100,
          ) * 0.01
        ).toFixed(2),
      ),
    );
  }

  getInputValue(event: Event) {
    return (event.target as HTMLInputElement).value;
  }

  updateSuggestedSum(stringValue: string) {
    console.log(stringValue);

    const parsedNumber = stringValue === '' ? undefined : parseCurrency(stringValue);
    const isInvalid = parsedNumber !== undefined && isNaN(parseFloat(stringValue));

    this.suggestedSumOfPositions.set({
      value: isInvalid ? stringValue : parsedNumber,
      validationError: isInvalid ? 'Der Wert ist kein gültiger Betrag' : undefined,
    });
  }

  protected readonly console = console;
  protected readonly getInputClasses = getInputClasses;

  protected getSuggestedSumDisplayValue(): string {
    const suggestedSum = this.suggestedSumOfPositions().value;
    return (
      (suggestedSum !== undefined && typeof suggestedSum === 'number'
        ? formatMoney(suggestedSum)
        : suggestedSum) ?? ''
    );
  }
}
