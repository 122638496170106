import {
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
  QueryList,
  ViewChildren,
} from '@angular/core';
import { MatIcon } from '@angular/material/icon';
import { MenuModule } from 'primeng/menu';
import { NgClass, NgStyle } from '@angular/common';
import { Dimension2d, TableData } from '../../../../../models/interfaces';
import { TableOverlayColumnAssignmentItemComponent } from './table-overlay-column-assignment-item/table-overlay-column-assignment-item.component';

@Component({
  selector: 'app-table-overlay-column-assignment',
  standalone: true,
  imports: [MatIcon, MenuModule, NgClass, NgStyle, TableOverlayColumnAssignmentItemComponent],
  templateUrl: './table-overlay-column-assignment.component.html',
})
export class TableOverlayColumnAssignmentComponent implements OnInit {
  @Input({ required: true }) tableData!: TableData;
  @Input({ required: true }) table!: HTMLDivElement;
  @Input({ required: true }) dimension!: Dimension2d;

  @Output() newTableHeaderAtColumnPosition = new EventEmitter<{
    colPos: number;
    newHeader: string;
  }>();

  @ViewChildren(TableOverlayColumnAssignmentItemComponent)
  menuChildren!: QueryList<TableOverlayColumnAssignmentItemComponent>;

  protected openedMenuIndex!: number | undefined;

  ngOnInit() {
    this.openedMenuIndex = this.tableData.confirmed
      ? undefined
      : this.tableData.columnHeaders.findIndex((header) => !header);
  }

  @HostListener('window:keydown', ['$event']) closeMenuIfMenuIsOpenedAndEscapeIsPressed(
    event: KeyboardEvent,
  ) {
    if (event.key === 'Escape' && this.getOpenedMenu()) this.closeOpenedMenu();
  }

  @HostListener('window:click', ['$event']) closeMenuOnClickIfOutsideOfMenu(
    event: MouseEvent,
  ): void {
    if (this.tableData.confirmed) return;

    const openedMenu = this.getOpenedMenu();

    if (!openedMenu) return;
    if (openedMenu.contains(event.target as HTMLElement)) return;

    this.closeOpenedMenu();
  }

  protected openMenuIfTableDataIsNotConfirmedAndCloseMenuIfOpened(colIndex: number) {
    if (this.tableData.confirmed) return;
    if (this.getOpenedMenu()) this.closeOpenedMenu();
    this.openedMenuIndex = colIndex;
  }

  private getOpenedMenu() {
    if (this.openedMenuIndex === undefined) return this.openedMenuIndex;

    return this.menuChildren.get(this.openedMenuIndex)?.menu?.el.nativeElement as
      | HTMLElement
      | undefined;
  }

  protected getMenuStyling(element: HTMLDivElement) {
    return {
      ['width']: `${this.table.offsetWidth}px`,
      ['height']: `100px`,
      ['top']: `-${10 + element.offsetHeight}px`,
      ['align-items']: 'end',
    };
  }

  private closeOpenedMenu() {
    this.openedMenuIndex = undefined;
  }

  private openNextUndefinedMenu(colIndex: number) {
    let nextUnsetColumnHeaderIndex;

    const areFollowingFieldsFilled = this.tableData.columnHeaders
      .slice(colIndex)
      .every((header) => Boolean(header));

    if (areFollowingFieldsFilled) {
      nextUnsetColumnHeaderIndex = this.tableData.columnHeaders.findIndex((header) => {
        return !header;
      });
    } else {
      nextUnsetColumnHeaderIndex = this.tableData.columnHeaders.findIndex((header, index) => {
        return !header && index >= colIndex;
      });
    }

    if (nextUnsetColumnHeaderIndex === -1) {
      this.closeOpenedMenu();
      return;
    }

    this.openMenuIfTableDataIsNotConfirmedAndCloseMenuIfOpened(nextUnsetColumnHeaderIndex);
  }

  setNewTableHeaderAtColumnPosition(colPos: number, newHeader: string) {
    this.newTableHeaderAtColumnPosition.emit({ colPos, newHeader });
    this.openNextUndefinedMenu(colPos);
  }
}
