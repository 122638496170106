import { inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { CtBatchApiService } from '../api/ct-batch-api.service';
import { CtBatchStoreService } from './ct-batch-store.service';
import { ConfirmService } from '@l21s-ecnps/gui-commons';
import { PersistDataService } from './persist-data.service';
import {
  AppView,
  AuditPatchDto,
  AuditPosition,
  AuditPositionDto,
  ExtractedFields,
} from '../../models/interfaces';
import { UntilDestroy } from '@ngneat/until-destroy';
import { PersonenschadenApiService } from '../api/personenschaden-api.service';
import dayjs from 'dayjs';
import { ExtractedDataStoreService } from '../extracted-data/extracted-data-store.service';
import { catchError, switchMap, throwError } from 'rxjs';
import { ConfigService } from '../config/config.service';

@UntilDestroy()
@Injectable({
  providedIn: 'root',
})
export class WorkflowService {
  private router = inject(Router);
  private ctBatchStoreService = inject(CtBatchStoreService);
  private ctBatchApiService = inject(CtBatchApiService);
  private confirmService = inject(ConfirmService);
  private persistDataService = inject(PersistDataService);
  private personenschadenApiService = inject(PersonenschadenApiService);
  private extractedDataStoreService = inject(ExtractedDataStoreService);
  private configService = inject(ConfigService);

  private readonly remainingViewQueue: AppView[] = [];
  private viewQueue: AppView[] = [];
  private currentView!: AppView;

  init() {
    const ctBatch = this.ctBatchStoreService.ctBatch;

    if (this.persistDataService.viewQueue && this.persistDataService.hasCurrentView) {
      this.viewQueue = this.persistDataService.viewQueue;

      this.fillRemainingViewQueue(this.getPositionInQueue());

      this.navigateInitially();
      return;
    }

    this.remainingViewQueue.push(AppView.FIELD_EXTRACTION);

    const areForderungsaufstellungenExisting = ctBatch.documents.some(
      (document) => document.documentClass === 'FORDERUNGSAUFSTELLUNG',
    );

    if (areForderungsaufstellungenExisting) {
      this.remainingViewQueue.push(AppView.TABLE_EXTRACTION);
    }

    console.log('workflow queue: ', this.remainingViewQueue);
    this.viewQueue = structuredClone(this.remainingViewQueue);
    this.persistDataService.viewQueue = this.viewQueue;

    this.navigateInitially();
  }

  private getPagePathByView(view: AppView): string {
    switch (view) {
      case AppView.FIELD_EXTRACTION:
        return 'fields';
      case AppView.TABLE_EXTRACTION:
        return 'tables';
      default:
        throw new Error('This should not happen');
    }
  }

  private navigateToAudit() {
    const ctBatchId = this.ctBatchStoreService.ctBatch.id;
    if (!ctBatchId) {
      return;
    }
    const auditId = this.ctBatchStoreService.ctBatch.auditId;

    const subscription = this.sendDataToPersonenschadenApi(auditId)
      .pipe(
        switchMap(() => this.ctBatchApiService.changeCtBatchStateToDone(ctBatchId)),
        catchError((error: unknown) => {
          this.confirmService.snackError('Fehler beim Abschließen des Extraktionsvorgangs.');
          return throwError(() => error);
        }),
      )
      .subscribe({
        next: () => {
          this.persistDataService.reset();
          window.location.replace(`${this.configService.config.auditUrl}/audit/${auditId}`);
        },
      });

    this.confirmService.spinner({ subscription });
  }

  private sendDataToPersonenschadenApi(auditId: string) {
    const extractedFields = structuredClone(this.extractedDataStoreService.extractedFields);
    const auditPositionArray = structuredClone(this.extractedDataStoreService.auditPositionArray);

    const auditPatch = this.createAuditPatchDtoFromStoredData(extractedFields, auditPositionArray);

    return this.personenschadenApiService.patchAudit(auditPatch, auditId);
  }

  private createAuditPatchDtoFromStoredData(
    extractedFields: ExtractedFields,
    auditPositions: AuditPosition[],
  ): AuditPatchDto {
    return {
      invoiceDate: extractedFields.InvoiceDate
        ? dayjs(extractedFields.InvoiceDate).format('YYYY-MM-DD')
        : undefined,
      birthDate: extractedFields.ClaimantBirthDate
        ? dayjs(extractedFields.ClaimantBirthDate).format('YYYY-MM-DD')
        : undefined,
      kstSign: extractedFields.KstSign,
      positions: auditPositions.map((auditPosition: AuditPosition) => {
        const toDate = auditPosition.to
          ? dayjs(auditPosition.to).format('YYYY-MM-DD')
          : dayjs(auditPosition.from).format('YYYY-MM-DD');

        return {
          from: dayjs(auditPosition.from).format('YYYY-MM-DD'),
          to: toDate,
          description: auditPosition.description,
          originalAmount: Number(auditPosition.originalAmount),
        } as AuditPositionDto;
      }),
    };
  }

  private navigateInitially() {
    if (this.persistDataService.hasCurrentView) {
      this.navigateToView(this.persistDataService.currentView!);
      this.remainingViewQueue.shift();
    } else {
      this.navigateToNextView();
    }
  }

  navigateToPrevView() {
    this.fillRemainingViewQueue(this.getPositionInQueue() - 1);
    this.navigateToNextView();
  }

  navigateToNextView() {
    if (this.remainingViewQueue.length > 0) {
      this.navigateToView(this.remainingViewQueue[0]);
      this.remainingViewQueue.shift();
    } else {
      this.navigateToAudit();
    }
  }

  private navigateToView(view: AppView) {
    const pagePath = this.getPagePathByView(view);

    this.router
      .navigate([`batch/${this.ctBatchStoreService.ctBatchId}/${pagePath}`])
      .catch(console.error);
    this.currentView = view;
    this.persistDataService.currentView = this.currentView;
  }

  resetAppView(): void {
    this.remainingViewQueue.push(...[AppView.FIELD_EXTRACTION, AppView.TABLE_EXTRACTION]);
    this.navigateToNextView();
  }

  getPositionInQueue(): number {
    return this.viewQueue.findIndex((view) => view === this.persistDataService.currentView);
  }

  isFirstViewInQueue(): boolean {
    return this.currentView === this.viewQueue[0];
  }

  isFieldExtractionView(): boolean {
    return this.currentView === AppView.FIELD_EXTRACTION;
  }

  isTableExtractionView(): boolean {
    return this.currentView === AppView.TABLE_EXTRACTION;
  }

  private fillRemainingViewQueue(startIndex: number): void {
    for (let i = startIndex; i < this.viewQueue.length; i++) {
      if (this.remainingViewQueue.length > 0) {
        this.remainingViewQueue[i] = this.viewQueue[i];
      } else {
        this.remainingViewQueue.push(this.viewQueue[i]);
      }
    }
  }
}
