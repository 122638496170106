import { KeycloakService } from 'keycloak-angular';
import { ConfigService } from '../services/config/config.service';
import { lastValueFrom } from 'rxjs';

export function initializeKeycloak(
  keycloak: KeycloakService,
  configService: ConfigService,
): () => Promise<boolean> {
  return () =>
    lastValueFrom(configService.loadConfig()).then((config) => {
      return keycloak.init({
        config: {
          url: config.keycloakUrl,
          realm: 'ps',
          clientId: 'claims-terminal-extraction-gui',
        },
        initOptions: {
          checkLoginIframe: false,
        },
      });
    });
}
