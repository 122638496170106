import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { ButtonState } from '../../models/interfaces';

@Injectable({
  providedIn: 'root',
})
export class ButtonStateService {
  private _nextButtonState = new Subject<ButtonState>();

  private _prevButtonState = new Subject<ButtonState>();

  public nextButtonState$ = this._nextButtonState.asObservable();
  public prevButtonState$ = this._prevButtonState.asObservable();

  setNextButtonState(state: ButtonState): void {
    this._nextButtonState.next(state);
  }

  setPrevButtonState(state: ButtonState): void {
    this._prevButtonState.next(state);
  }
}
