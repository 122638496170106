import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
//communication of removed table
export class ExtractedTableRemovalService {
  destroyedTable$ = new Subject<{ tableId: string; pageId: string }>();

  public destroyTable(tableId: string, pageId: string) {
    this.destroyedTable$.next({ tableId: tableId, pageId: pageId });
  }
}
