<div
  class="row group relative flex flex-row items-center gap-2 border-t border-t-theme-gray-400 py-2 hover:bg-theme-blue-gray"
>
  @for (header of tableHeader; track header; let headerIndex = $index) {
    <div [ngClass]="getWidthForInput(header)">
      <input
        class="block h-full w-full rounded border p-1 text-left ring-1"
        [type]="getInputType(header)"
        [ngClass]="getInputClasses(row, rowIndex, currentFieldIndex, headerIndex, header)"
        (focusout)="focusOutInput.emit({ event: $event, header, rowIndex })"
        (click)="newCurrentFieldIndex.emit(rowIndex * tableHeader.length + headerIndex)"
        [attr.data-row]="rowIndex"
        [attr.data-header]="header"
        [value]="getCellData(row, header)"
        [matTooltip]="getTooltipData(row, header) ?? ''"
        matTooltipClass="basic-tooltip"
        matTooltipPosition="before"
      />
    </div>
  }
  <button
    (click)="deleteRowAtIndex.emit(rowIndex)"
    matTooltip="Zeile löschen"
    matTooltipClass="basic-tooltip"
    matTooltipPosition="before"
    class="invisible flex h-[26px] w-[26px] items-center justify-center rounded-md bg-theme-danger group-hover:visible"
  >
    <mat-icon [ngStyle]="{ color: 'white' }">close</mat-icon>
  </button>
  <div class="addRowOverlay group/add-button -top-2" (dblclick)="onAddRow(rowIndex, row)">
    <div class="addRowLine"></div>
    <button
      class="addRowButton"
      matTooltip="Zeile einfügen"
      matTooltipClass="basic-tooltip"
      (click)="onAddRow(rowIndex, row)"
    >
      <mat-icon [ngStyle]="{ color: 'white' }">add</mat-icon>
    </button>
  </div>
  @if (topAndBottomAddLineControls) {
    <div class="addRowOverlay group/add-button -bottom-2" (dblclick)="onAddRow(rowIndex + 1, row)">
      <div class="addRowLine"></div>
      <button
        class="addRowButton"
        matTooltip="Zeile einfügen"
        matTooltipClass="basic-tooltip"
        (click)="onAddRow(rowIndex + 1, row)"
      >
        <mat-icon [ngStyle]="{ color: 'white' }">add</mat-icon>
      </button>
    </div>
  }
</div>
