<button
  [matTooltip]="
    shouldDisableConfirmButton() ? 'Bitte definiere den Inhalt von mindestens einer Spalte.' : ''
  "
  matTooltipClass="basic-tooltip"
  [disabled]="shouldDisableConfirmButton()"
  class="pointer-events-auto flex w-fit cursor-pointer items-center justify-center gap-1.5 rounded-md bg-primary px-2 py-1 disabled:cursor-crosshair disabled:bg-theme-gray-500"
  (click)="buttonClicked.emit(); $event.stopPropagation()"
  (mouseup)="$event.stopPropagation()"
  (mousedown)="$event.preventDefault(); $event.stopPropagation()"
>
  <mat-icon class="flex !h-fit !w-fit text-sm">check_circle</mat-icon>
  <span class="block h-fit w-fit text-[0.6rem] uppercase">Bestätigen</span>
</button>
