import { enableProdMode } from '@angular/core';
import { environment } from './environments/environment';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import de from 'dayjs/locale/de';
import { twoDigitDateParseFunction } from '@l21s-ecnps/gui-commons/date-time';
import { bootstrapApplication } from '@angular/platform-browser';
import { AppComponent } from './app/app.component';
import { registerLocaleData } from '@angular/common';
import localeDe from '@angular/common/locales/de';
import localeDeExtra from '@angular/common/locales/extra/de';
import { getProviders } from './app.config';

if (environment.production) {
  enableProdMode();
}

dayjs.extend(customParseFormat, {
  parseTwoDigitYear: twoDigitDateParseFunction,
});
dayjs.locale(de);

registerLocaleData(localeDe, 'de-DE', localeDeExtra);

bootstrapApplication(AppComponent, {
  providers: getProviders(),
}).catch(console.error);
