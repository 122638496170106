import { HttpClient } from '@angular/common/http';
import { Observable, tap } from 'rxjs';
import { Config } from '../../models/interfaces';

//as of https://www.thisdot.co/blog/runtime-environment-configuration-with-angular
//unfortunately there is no nice and standardized way in angular to do runtime config...
export class ConfigService {
  config!: Config;

  constructor(private http: HttpClient) {}

  loadConfig(): Observable<Config> {
    return this.http
      .get<Config>('./assets/config/config.json')
      .pipe(tap((config) => (this.config = config)));
  }
}
