<div class="flex gap-4 rounded-md border border-theme-gray-500 bg-white p-2 text-xs">
  <div class="flex flex-col">
    <span class="font-bold">Mandant</span>
    <span>{{ ctBatch.tenant }}</span>
  </div>
  <div class="flex flex-col">
    <span class="font-bold">Schadennummer</span>
    <span
      >{{ ctBatch.claimNumber
      }}{{ ctBatch.businessTransaction ? "-" + ctBatch.businessTransaction : "" }}</span
    >
  </div>
  <div class="flex flex-col">
    <span class="font-bold">Typ</span>
    <span>{{ ctBatch.proofType }}</span>
  </div>
</div>
