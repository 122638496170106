import { inject } from '@angular/core';
import { KeycloakService } from 'keycloak-angular';

export interface UserProfile {
  username?: string;
}

export abstract class AuthenticationService {
  abstract login: (redirectUri: string) => Promise<void>;
  abstract logout: () => Promise<void>;
  abstract isLoggedIn: () => boolean;
  abstract loadUserProfile: () => Promise<UserProfile>;
  abstract getUserName: () => string;
}

export class KeycloakAuthenticationService extends AuthenticationService {
  private keycloakService = inject(KeycloakService);

  override logout = () => this.keycloakService.logout();
  override login = (redirectUri: string) => this.keycloakService.login({ redirectUri });
  override isLoggedIn = () => this.keycloakService.isLoggedIn();
  override loadUserProfile = () => this.keycloakService.loadUserProfile();
  override getUserName = () => this.keycloakService.getUsername();
}
