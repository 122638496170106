/**
 * Usage:
 *
 * ```ts
 * const array = ["banana", undefined, "cookies"]
 * const safeArray = array.filter(isDefined)
 * ```
 *
 * While `array` is of type `(string | undefined)[]`, the result `safeArray`
 * is of type `string[]` (and contains only strings).
 */
export const isDefined = <T>(t: T | undefined): t is T => t !== undefined;
