import { Component, inject, Input, OnInit } from '@angular/core';
import { CtBatchApiService } from '../../../services/api/ct-batch-api.service';
import { ConfirmService } from '@l21s-ecnps/gui-commons';
import { ConfigService } from '../../../services/config/config.service';
import { CtBatchStoreService } from '../../../services/app-state/ct-batch-store.service';
import { MatIcon } from '@angular/material/icon';
import { CtBatchDetailsComponent } from './work-item-details/ct-batch-details.component';
import { MatButton } from '@angular/material/button';
import { environment } from '../../../../environments/environment';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { CtBatch } from '../../../models/ct-batch-model';
import { AuthenticationService } from '../../../services/authentication.service';

@UntilDestroy()
@Component({
  selector: 'app-header',
  standalone: true,
  templateUrl: './header.component.html',
  imports: [MatIcon, CtBatchDetailsComponent, MatButton],
})
export class HeaderComponent implements OnInit {
  private ctBatchApiService = inject(CtBatchApiService); //
  private confirmService = inject(ConfirmService); //
  private configService = inject(ConfigService); //
  private authenticationService = inject(AuthenticationService); //
  private ctBatchStoreService = inject(CtBatchStoreService); //

  ctBatch!: CtBatch;
  metadata!: Record<string, string>;

  @Input({ required: true }) usersFullName = '';

  ngOnInit() {
    this.ctBatch = this.ctBatchStoreService.ctBatch;
  }

  logout() {
    void this.authenticationService.logout();
  }

  unlockItemAndOpenProof() {
    this.ctBatchApiService
      .unlockCtBatch(this.ctBatch.id)
      .pipe(untilDestroyed(this))
      .subscribe({
        next: () => {
          window.location.replace(`${this.configService.config.auditUrl}`);
        },
        error: () => {
          this.confirmService.snackError(
            `Vorgang ${this.ctBatch.id} konnte nicht entsperrt werden`,
          );
        },
      });
  }

  protected readonly environment = environment;
}
