<div class="flex h-full w-full flex-col rounded-xl bg-white drop-shadow-card">
  <div
    class="flex h-fit w-full flex-row items-center gap-x-3 border-b border-b-theme-blue-gray p-1 pl-4"
  >
    <img
      src="../../../../assets/icons/table-list-solid.svg"
      loading="lazy"
      alt=""
      class="h-7 invert-[80%] filter"
    />
    <span class="flex items-center gap-4 p-1 text-lg font-bold">
      <span class="py-3">Forderungen</span>
      @if (areSumsDiverging()) {
        <div
          class="flex items-center gap-3 rounded-xl bg-theme-warning-light p-2 text-xs text-theme-gray-900"
        >
          <mat-icon class="text-theme-warning">warning</mat-icon>
          <span
            >Die Summe der Einzelbeträge stimmt nicht mit der<br />Gesamtforderung überein. Bitte
            überprüfe beide</span
          >
        </div>
      }
    </span>
  </div>

  @if (!buildTable) {
    <div
      class="flex max-h-full w-full grow flex-col items-center justify-center gap-4 overflow-y-auto"
    >
      <div class="m-auto flex flex-col items-center gap-4 p-16 text-center">
        <img src="../../../../assets/images/generate_table.svg" loading="lazy" alt="" />
        <p class="w-fit break-normal text-xl text-theme-blue">
          Zeichne eine Tabelle direkt ins Dokument oder erstelle die Tabelle manuell
        </p>
        <button
          class="group flex w-fit flex-row gap-x-2 rounded-md border p-4 hover:bg-theme-gray-500"
          (click)="startManualCreation()"
        >
          <img
            alt=""
            src="../../../../assets/icons/table-list-solid.svg"
            loading="lazy"
            class="invert-[80%] filter"
          />
          <span class="uppercase">Manuell erstellen</span>
        </button>
      </div>
    </div>
  } @else {
    <div class="max-h-full overflow-auto">
      <app-data-capturing-table
        [tableRows]="tableRows"
        [currentFieldIndex]="currentFieldIndex()"
        (tableElementsChanged)="onTableChange()"
        (rowAdded)="addRowAtIndex($event.index, $event.tableId, $event.pageId)"
        (rowDeleted)="removeRowAtIndex($event)"
        (updateCurrentFieldIndex)="activateTableFieldAtIndex($event)"
        (setRowData)="setRowDataAtIndex($event.event, $event.rowIndex)"
        #dataCapturingTableComponent
      />
    </div>
    <div class="sticky bottom-0 flex p-4 pb-5 pr-[calc(1rem+0.5rem+26px)]">
      <div class="grid w-full grid-cols-[auto_min-content] items-center justify-items-end gap-2">
        <div class="ml-auto font-bold">Forderungssumme</div>
        <!-- TODO: we could refactor the classList with error / focus states into a directive -->
        <input
          #x
          (change)="
            updateSuggestedSum(getInputValue($event)); x.blur(); suggestedSumInputActive = false
          "
          class="justify-self-start rounded border p-1 ring-1"
          (focus)="suggestedSumInputActive = true; currentFieldIndex.set(undefined)"
          size="30"
          [value]="getSuggestedSumDisplayValue()"
          [ngClass]="
            getInputClasses(
              suggestedSumOfPositions().value,
              suggestedSumInputActive,
              suggestedSumOfPositions().validationError !== undefined || areSumsDiverging()
            ) +
            ' ' +
            (!areSumsDiverging() && getSuggestedSumDisplayValue() ? 'border-green-500' : '')
          "
          matTooltipClass="basic-tooltip"
          matTooltipPosition="before"
          [matTooltip]="suggestedSumOfPositions().validationError ?? ''"
        />
        <div></div>
        <span class="justify-self-start" [ngClass]="areSumsDiverging() ? 'visible' : 'invisible'">
          Summe Einzelbeträge: {{ calculatedSumOfPositions() }}
        </span>
      </div>
    </div>
    <p-confirmDialog #divergingSumDialog>
      <ng-template pTemplate="headless" let-message>
        <app-dialog
          title="Abweichende Forderungssummen"
          acceptText="Bestätigen"
          closeText="Abbrechen"
          (acceptDialog)="divergingSumDialog.accept()"
          (closeDialog)="divergingSumDialog.reject()"
        >
          <p class="p-6">
            Die eingegebene Gesamtsumme entspricht nicht der Summe der Einzelbeträge. Bist du
            sicher, dass die eingegebenen Daten korrekt sind?
          </p>
        </app-dialog>
      </ng-template>
    </p-confirmDialog>
  }
</div>
