<div class="flex h-full flex-col gap-y-4 bg-secondary antialiased">
  @if (doneLoading) {
    <app-header [usersFullName]="usersFullName" />
    <div class="flex h-full min-h-0 flex-col gap-y-4 px-4">
      <div class="h-full min-h-0">
        <!-- Change component on step in extraction process -->
        <router-outlet />
      </div>
    </div>
    <app-footer />
  } @else {
    <span>loading...</span>
  }
</div>
