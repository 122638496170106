import { Component, Input } from '@angular/core';
import { OverlayRectComponent } from '../../overlay-rect/overlay-rect.component';
import { WordRect } from '../../../../models/interfaces';

@Component({
  selector: 'app-table-creation-overlay',
  standalone: true,
  imports: [OverlayRectComponent],
  templateUrl: './table-creation-overlay.component.html',
})
export class TableCreationOverlayComponent {
  @Input({ required: true }) currentLasso?: WordRect;
  @Input({ required: true }) pageId!: string;
}
