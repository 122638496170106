import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TableData } from '../../../../../models/interfaces';
import { MatIcon } from '@angular/material/icon';

@Component({
  selector: 'app-table-overlay-back-button',
  standalone: true,
  imports: [MatIcon],
  templateUrl: './table-overlay-back-button.component.html',
})
export class TableOverlayBackButtonComponent {
  @Input({ required: true }) tableData!: TableData;
  @Output() buttonClicked = new EventEmitter<void>();
}
