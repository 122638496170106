import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatIcon } from '@angular/material/icon';
import { NgClass, NgStyle } from '@angular/common';
import {
  LineOrientation,
  remap,
  TableOverlayLine,
  WordRect,
} from '../../../../../models/interfaces';

@Component({
  selector: 'app-table-overlay-line',
  standalone: true,
  imports: [MatIcon, NgClass, NgStyle],
  templateUrl: './table-overlay-line.component.html',
})
export class TableOverlayLineComponent {
  @Input({ required: true }) line!: TableOverlayLine;
  @Input({ required: true }) hideEventtarget!: boolean;
  @Input({ required: true }) tableRect!: WordRect;
  @Input({ required: true }) tableRef!: HTMLDivElement;

  @Output() selected = new EventEmitter<void>();

  @Output() delete = new EventEmitter<void>();

  protected get isDeletable(): boolean {
    return !this.line.isBoundary;
  }

  protected getStretchStyle() {
    const offsetPivot = this.line.orientation === LineOrientation.VERTICAL ? 'left' : 'top';
    return {
      [offsetPivot]: `${
        this.line.orientation === LineOrientation.VERTICAL
          ? this.getTableXInPixels(this.line.offset)
          : this.getTableYInPixels(this.line.offset)
      }px`,
    };
  }

  getTableYInPixels(relativeOverlayY: number): number {
    return (
      remap(relativeOverlayY, this.tableRect.y[0], this.tableRect.y[1], 0, 1) *
      this.tableRef.offsetHeight
    );
  }

  getTableXInPixels(relativeOverlayX: number): number {
    return (
      remap(relativeOverlayX, this.tableRect.x[0], this.tableRect.x[1], 0, 1) *
      this.tableRef.offsetWidth
    );
  }

  protected getButtonStyleClasses(): string[] {
    switch (this.line.orientation) {
      case LineOrientation.VERTICAL:
        return ['top-0 -left-2'];
      case LineOrientation.HORIZONTAL:
        return ['left-0', '-top-1.5'];
      default:
        return [];
    }
  }

  protected readonly LineOrientation = LineOrientation;
}
