import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import {
  NOT_IMPORTANT_TABLE_HEADER,
  TableData,
  tableHeaders,
} from '../../../../../../models/interfaces';
import { MatIcon } from '@angular/material/icon';
import { Menu, MenuModule } from 'primeng/menu';
import { NgClass } from '@angular/common';
import { MenuItem, MenuItemCommandEvent } from 'primeng/api';

@Component({
  selector: 'app-table-overlay-column-assignment-item',
  standalone: true,
  imports: [MatIcon, MenuModule, NgClass],
  templateUrl: './table-overlay-column-assignment-item.component.html',
})
export class TableOverlayColumnAssignmentItemComponent implements OnInit {
  @Input({ required: true }) tableData!: TableData;
  @Input({ required: true }) columnIndex!: number;
  @Input({ required: true }) columnWidth!: number;
  @Input({ required: true }) isMenuOpened!: boolean;

  @Output() columnButtonClicked = new EventEmitter<number>();
  @Output() newTableHeaderAtColumnPosition = new EventEmitter<{
    colPos: number;
    newHeader: string;
  }>();

  @ViewChild('menu') menu!: Menu | undefined;

  protected menuModel: MenuItem[] = [];
  protected readonly NOT_IMPORTANT_TABLE_HEADER = NOT_IMPORTANT_TABLE_HEADER;
  private styleString = '';

  ngOnInit() {
    this.menuModel = this.getMenuItems(this.columnIndex);
  }

  protected getTranslate() {
    if (this.styleString) return this.styleString;

    const menuElement = this.menu?.el.nativeElement as HTMLElement | undefined;
    const div = menuElement?.querySelector<HTMLElement>('.header-menu');
    const imgContainer = document.getElementById('img-container');
    if (div && imgContainer) {
      const menuStart = div.getBoundingClientRect().top - div.offsetHeight;
      const imgContainerStart = imgContainer.getBoundingClientRect().top;

      if (menuStart < imgContainerStart) {
        this.styleString = ' translate-y-1';
        return ' translate-y-1';
      } else {
        this.styleString = ' -translate-y-[290px]';
        return ' -translate-y-[290px]';
      }
    }

    return '';
  }

  private getMenuItems(columnIndex: number): MenuItem[] {
    return [
      {
        label: 'Spalte',
        items: tableHeaders.concat([NOT_IMPORTANT_TABLE_HEADER]).map((header) => {
          return {
            label: header,
            command: (event) => {
              this.setColumnHeader(header, columnIndex, event);
            },
          };
        }),
      },
    ];
  }

  protected setColumnHeader(header: string, columnIndex: number, event: MenuItemCommandEvent) {
    event.originalEvent?.stopPropagation();
    this.newTableHeaderAtColumnPosition.emit({ colPos: columnIndex, newHeader: header });
  }
}
