import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class OcrSelectionService {
  selectedWords$: Subject<string[]> = new Subject<string[]>();

  public newSelectedWords(words: string[]) {
    this.selectedWords$.next(words);
  }
}
