import { Injectable, Pipe, PipeTransform } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { PageApiClient } from './page-api-client.service';
import { logError } from '../../utils/Rxjs.utils';

@Pipe({
  name: 'loadImage',
  standalone: true,
})
export class LoadImagePipe implements PipeTransform {
  constructor(private pageImageService: PageImageService) {}

  transform(pageId: string): Observable<string> {
    return this.pageImageService.getImageSrc(pageId);
  }
}

@Injectable({
  providedIn: 'root',
})
export class PageImageService {
  public imageSubjectById = new Map<string, BehaviorSubject<string>>();

  private readonly defaultImage = 'assets/images/ecn-logo.svg';

  constructor(private pageApiClient: PageApiClient) {}

  public init(pageIds: string[]) {
    for (const pageId of pageIds) {
      const subject = new BehaviorSubject(this.defaultImage);
      this.imageSubjectById.set(pageId, subject);

      this.pageApiClient
        .getImageForPage(pageId)
        .pipe(logError())
        .subscribe((imageBlob) => subject.next(URL.createObjectURL(imageBlob)));
    }
  }

  public getImageSrc(pageId: string): Observable<string> {
    return this.getImageSubject(pageId);
  }

  private getImageSubject(pageId: string): BehaviorSubject<string> {
    if (!this.imageSubjectById.has(pageId)) {
      throw new Error(`image src requested for id '${pageId}' which does not exist in subject map`);
    }

    return this.imageSubjectById.get(pageId)!;
  }

  public isImageLoaded(pageId: string): boolean {
    return this.getImageSubject(pageId).value !== this.defaultImage;
  }
}
