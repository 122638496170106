import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { TableRow } from '../../models/interfaces';

@Injectable({
  providedIn: 'root',
})
//if table creation in overlay is confirmed a table is created
export class ExtractedTableCreationService {
  createdTable$: Subject<TableRow[]> = new Subject<TableRow[]>();

  public createTable(tableRows: TableRow[]) {
    this.createdTable$.next(tableRows);
  }
}
