import { Pipe, PipeTransform } from '@angular/core';
import { Dimension2d, WordRect } from '../../../models/interfaces';

@Pipe({
  name: 'scaleRect',
  standalone: true,
})
export class ScaleRectPipe implements PipeTransform {
  transform(rect: WordRect, dimension: Dimension2d) {
    scaleRect(rect, dimension);
  }
}

export const scaleRect = (rect: WordRect, dimension: Dimension2d): WordRect => {
  const x = rect.x.map((x) => x * dimension.width) as [number, number];
  const y = rect.y.map((x) => x * dimension.height) as [number, number];

  return { x, y };
};
