import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
// stores value if table creation is toggled or not (toolbar)
export class ToolbarService {
  tableCreationToggled$ = new BehaviorSubject<boolean>(true);

  public setTableCreationToggled(toggled: boolean): void {
    this.tableCreationToggled$.next(toggled);
  }

  public getTableCreationToggled(): boolean {
    return this.tableCreationToggled$.value;
  }
}
