import { inject, Pipe, PipeTransform } from '@angular/core';
import { OverlayDimensionService } from '../../../services/overlay/overlay-dimension.service';
import { WordRect } from '../../../models/interfaces';
import { scaleRect } from './scale-rect.pipe';

@Pipe({
  name: 'scaleOverlayRect',
  standalone: true,
})
export class ScaleOverlayRectPipe implements PipeTransform {
  private overlayDimensionService = inject(OverlayDimensionService);

  transform(rect: WordRect, pageId: string): WordRect {
    return scaleRect(rect, this.overlayDimensionService.dimensionsByPage$.value[pageId]);
  }
}
