import { tableHeaderConstants } from '../models/interfaces';

export function getWidthForInput(header: string) {
  switch (header) {
    case tableHeaderConstants.Beschreibung:
      return 'w-12/24';
    case tableHeaderConstants.Von:
    case tableHeaderConstants.Bis:
      return 'w-3/12';
    case tableHeaderConstants.Betrag:
      return 'w-4/12';
    default:
      return 'w-[24px]';
  }
}
