import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Dimension2d } from '../../models/interfaces';

@Injectable({
  providedIn: 'root',
})
//stores dimension of single page for overlay
export class OverlayDimensionService {
  dimensionsByPage$ = new BehaviorSubject<Record<string, Dimension2d>>({});

  setDimension(pageId: string, dimension: Dimension2d) {
    const dimensions = structuredClone(this.dimensionsByPage$.value);
    dimensions[pageId] = dimension;
    this.dimensionsByPage$.next(dimensions);
  }
}
