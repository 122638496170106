import { Component, Input } from '@angular/core';
import { AsyncPipe, NgClass } from '@angular/common';
import { CtPage } from '../../../../models/ct-batch-model';
import { LoadImagePipe } from '../../../../services/api/page-image.service';

@Component({
  selector: 'app-image-list-preview-item-page',
  standalone: true,
  imports: [NgClass, LoadImagePipe, AsyncPipe],
  templateUrl: './image-list-preview-item-page.component.html',
})
export class ImageListPreviewItemPageComponent {
  @Input({ required: true }) selected!: boolean;
  @Input({ required: true }) index!: number;
  @Input({ required: true }) page!: CtPage;
}
