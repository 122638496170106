import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ConfigService } from '../config/config.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PageApiClient {
  constructor(
    private http: HttpClient,
    private configService: ConfigService,
  ) {}

  getImageForPage(pageId: string): Observable<Blob> {
    return this.http.get(`${this.configService.config.apiBaseUrl}/api/page/${pageId}/image`, {
      responseType: 'blob',
    });
  }
}
