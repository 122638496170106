<div class="flex h-full w-10 flex-col items-center gap-2 rounded-l-lg bg-zinc-600 pt-3 text-white">
  <div class="flex flex-col items-stretch text-center">
    <span>{{ currentPageIndex + 1 }}</span>
    <hr />
    <span>{{ totalPageCount }}</span>
  </div>
  <hr class="w-2/3 border-theme-gray-700" />
  <app-toolbar-button
    iconName="zoom-in"
    (buttonClick)="zoomChange.emit(zoomStep)"
    matTooltip="Vergrößern"
    matTooltipClass="basic-tooltip"
    matTooltipPosition="right"
  />
  <app-toolbar-button
    iconName="zoom-out"
    (buttonClick)="zoomChange.emit(-zoomStep)"
    matTooltip="Verkleinern"
    matTooltipClass="basic-tooltip"
    matTooltipPosition="right"
  />
  <app-toolbar-button
    iconName="{{ fitType === FitType.WIDTH ? 'fit-height' : 'fit-width' }}"
    (buttonClick)="toggleFitType.emit()"
    matTooltip="An Fenster anpassen"
    matTooltipClass="basic-tooltip"
    matTooltipPosition="right"
  />
  <hr class="w-2/3 border-theme-gray-700" />
  <app-toolbar-button
    iconName="rotate-right"
    (buttonClick)="onRotateClicked()"
    [disabled]="areTablesOnCurrentPage"
    [matTooltip]="
      areTablesOnCurrentPage
        ? 'Seiten können nicht rotiert werden, wenn bereits ein Tabellenlayout erstellt wurde'
        : 'Rotieren'
    "
    matTooltipClass="basic-tooltip"
    matTooltipPosition="right"
  />
  <hr class="w-2/3 border-theme-gray-700" />
  @if (isTableView) {
    <div class="flex grow flex-col items-center gap-y-2.5 pb-3">
      <app-toolbar-button
        [disabled]="doUnconfirmedTablesExist"
        [additionalClasses]="isTableCreationToggled ? 'bg-[#E9F4F8]' : 'hover:bg-theme-gray-500'"
        [isActive]="isTableCreationToggled"
        (click)="toggleTableCreation.emit()"
        iconName="table-creation"
        matTooltip="Tabelle erstellen"
        matTooltipClass="basic-tooltip"
        matTooltipPosition="right"
      />
      <app-table-extraction-tool-hints class="mt-auto" />
    </div>
  }
</div>
