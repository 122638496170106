<section
  class="flex h-full w-full shrink-0 flex-col items-center justify-center"
  (waIntersectionObservee)="
    $event[0].isIntersecting &&
      pageIntersection.emit({ documentType: document.documentClass, pageId: page.id })
  "
  [attr.data-document-type]="document.documentClass"
>
  @if (pageImageService.isImageLoaded(page.id)) {
    <img
      draggable="false"
      class="h-full w-full"
      [attr.data-rotation]="0"
      loading="lazy"
      [src]="page.id | loadImage | async"
      #image
    />
  } @else {
    <mat-icon>spinner</mat-icon>
  }
</section>
