export function parseCurrency(currencyString: string): number {
  return Number(
    currencyString
      .replaceAll(/[Oo]/g, '0') // replace all o's with 0's
      .replaceAll(/[^0-9.,-]/g, '') // remove all non-numeric characters
      // This regular expression performs several operations:
      // 1. `(?<=.)[-]`: Matches a minus sign `-` that is preceded by any character.
      // 2. `(?<=[.,])[-]`: Matches a minus sign `-` that is immediately preceded by a period `.` or a comma `,`.
      // 3. `[.,](?=.*[.,](?=.*\d))`: Matches a period `.` or a comma `,` if there's another period or comma followed by a digit somewhere ahead in the string.
      // 4. `[.,](?!.*\d)`: Matches a period `.` or a comma `,` if there's no digit anywhere ahead in the string.
      // The `|` operator denotes OR, meaning that the regular expression will match any of the four conditions.
      // The `g` flag means "global", so it will replace all matches in the string, not just the first one.
      // The `.replace(..., '')` function call replaces all matches of the regular expression with an empty string, effectively removing them from the original string.
      .replace(/(?<=.)[-]|(?<=[.,])[-]|[.,](?=.*[.,](?=.*\d))|[.,](?!.*\d)/g, '')
      .replace(',', '.'), // replace comma with dot
  );
}
