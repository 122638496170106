import { inject, Injectable } from '@angular/core';
import { ExtractedDataStoreService } from '../services/extracted-data/extracted-data-store.service';
import { CtBatch } from '../models/ct-batch-model';
import { TableOverlayStoreService } from '../services/overlay/table-overlay-store.service';
import { ExtractedFields } from '../models/interfaces';
import { getBuildSimpleExtractedFields } from './build-simple-field-processing';
import { BuildSimpleTable, getBuildSimpleExtractedTables } from './build-simple-table-processing';
import { BuildSimpleExtractionResultDto } from './build-simple-model';

@Injectable({ providedIn: 'root' })
export class BuildSimpleExtractedDataProcessingService {
  private extractedDataStoreService = inject(ExtractedDataStoreService);
  private tableOverlayStoreService = inject(TableOverlayStoreService);

  storeExtractedSuggestions(
    ctBatch: CtBatch,
    extractionDataByDocumentId: Record<string, BuildSimpleExtractionResultDto>,
  ) {
    const extractedFields = getBuildSimpleExtractedFields(extractionDataByDocumentId);
    this.storeExtractedFields(extractedFields);

    const extractedTables = getBuildSimpleExtractedTables(ctBatch, extractionDataByDocumentId);
    this.storeExtractedTables(extractedTables);
  }

  private storeExtractedFields(extractedFields: ExtractedFields) {
    this.extractedDataStoreService.extractedFields = extractedFields;
  }

  private storeExtractedTables(extractedTables: BuildSimpleTable[]) {
    this.tableOverlayStoreService.deleteAllTables();
    this.tableOverlayStoreService.storeInAppState();
    for (const table of extractedTables) {
      this.tableOverlayStoreService.addNewTable(table.overlayData);
    }
    this.extractedDataStoreService.setAuditPositionArray(
      extractedTables.map((table) => table.dataRows).flat(),
    );
  }
}
