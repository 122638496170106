<div class="flex h-full min-h-0 w-full gap-4">
  <app-document-preview
    class="w-0 flex-1"
    [documents]="[currentDocument]"
    (openPrevDocumentEmitter)="setPrevDocument($event)"
  />
  <app-data-capturing-table-view class="flex-1" (openNextDocumentEmitter)="setNextDocument()" />
</div>
<app-completion-dialog
  #completionDialog
  (acceptedDialog)="completeProcess()"
  (clickedEdit)="workflowService.resetAppView()"
  (clickedForderungenEdit)="currentDocumentIndex.set(0)"
  [extractedFieldsObject]="getExtractedFieldObject()"
  [auditPositionArray]="getAuditPositionArray()"
/>
<app-discard-table-dialog #discardTableDialog (acceptedDialog)="discardAndGoToPrevView()" />
