import { Component, EventEmitter, Input, Output } from '@angular/core';
import { tableHeaderConstants, tableHeaders, TableRow } from '../../../../../models/interfaces';
import { MatIcon } from '@angular/material/icon';
import { TooltipModule } from 'primeng/tooltip';
import { CurrencyPipe, DatePipe, NgClass, NgStyle } from '@angular/common';
import { LocalizedDatePipe } from '../../../../../pipes/localized-date.pipe';
import { getWidthForInput } from '../../../../../utils/DataCapturingTableWidth.utils';
import { getInputClasses } from '../../../../../utils/InputValidation.utils';
import dayjs from 'dayjs';
import { MatTooltip } from '@angular/material/tooltip';
import { formatMoney } from '../../../../../utils/FormatMoney.utils';

@Component({
  selector: 'app-data-capturing-table-item',
  standalone: true,
  imports: [MatIcon, TooltipModule, NgStyle, NgClass, DatePipe, CurrencyPipe, MatTooltip],
  templateUrl: './data-capturing-table-item.component.html',
  styleUrl: './data-capturing-table-item.component.scss',
})
export class DataCapturingTableItemComponent {
  protected readonly tableHeader = tableHeaders;
  private localizedDatePipe = new LocalizedDatePipe();

  @Input({ required: true }) row!: TableRow;
  @Input({ required: true }) rowIndex!: number;
  @Input({ required: true }) currentFieldIndex: number | undefined;
  @Input({ required: true }) topAndBottomAddLineControls = false;

  @Output() deleteRowAtIndex = new EventEmitter<number>();
  @Output() addRowAtIndex = new EventEmitter<{
    index: number;
    tableId: string | undefined;
    pageId: string | undefined;
  }>();

  @Output() newCurrentFieldIndex = new EventEmitter<number>();
  @Output() focusOutInput = new EventEmitter<{ event: Event; header: string; rowIndex: number }>();

  getCellData(row: TableRow, header: string) {
    switch (header) {
      case tableHeaderConstants.Von:
        if (!row.from.value) return '';
        if (!dayjs(row.from.value).isValid()) {
          return row.from.valueAsString;
        }
        return this.localizedDatePipe.transform(row.from.value);
      case tableHeaderConstants.Bis:
        if (!row.to.value) return '';
        if (!dayjs(row.to.value).isValid()) {
          return row.to.valueAsString;
        }
        return this.localizedDatePipe.transform(row.to.value);
      case tableHeaderConstants.Betrag:
        if (!row.amount.value) return '';
        if (isNaN(row.amount.value)) {
          row.amount.validationError = 'Invalides Zahlenformat. Bitte nutze das Format 1234,56.';
          return row.amount.value;
        }
        return formatMoney(row.amount.value);
      default:
        if (!row.description.value) return '';
        return row.description.value;
    }
  }

  getInputClasses(
    row: TableRow,
    rowIndex: number,
    currentFieldIndex: number | undefined,
    headerIndex: number,
    header: string,
  ) {
    const isCurrentInput = currentFieldIndex === rowIndex * tableHeaders.length + headerIndex;
    let displayError: boolean;
    let value: unknown;

    switch (header) {
      case tableHeaderConstants.Von:
        value = row.from.value;
        displayError = !!row.from.validationError;
        break;
      case tableHeaderConstants.Bis:
        value = row.to.value;
        displayError = !!row.to.validationError;
        break;
      case tableHeaderConstants.Betrag:
        value = row.amount.value;
        displayError = !!row.amount.validationError;
        break;
      default:
        value = row.description.value;
        displayError = !!row.description.validationError;
        break;
    }

    return getInputClasses(value, isCurrentInput, displayError);
  }

  protected readonly getWidthForInput = getWidthForInput;

  getInputType(header: string) {
    switch (header) {
      default:
        return 'text';
    }
  }

  getTooltipData(row: TableRow, header: string) {
    switch (header) {
      case tableHeaderConstants.Von:
        return row.from.validationError;
      case tableHeaderConstants.Bis:
        return row.to.validationError;
      case tableHeaderConstants.Betrag:
        return row.amount.validationError;
      default:
        return row.description.validationError;
    }
  }

  protected onAddRow(index: number, row: TableRow) {
    this.addRowAtIndex.emit({ index, tableId: row.tableId, pageId: row.pageId });
  }
}
