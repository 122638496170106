import { Component, Input } from '@angular/core';
import { WordRect } from '../../../models/interfaces';
import { RectDisplayStylePipe } from './rect-display-style.pipe';
import { NgClass, NgStyle } from '@angular/common';
import { ScaleOverlayRectPipe } from './scale-overlay-rect.pipe';

@Component({
  selector: 'app-overlay-rect',
  standalone: true,
  imports: [RectDisplayStylePipe, NgClass, NgStyle, ScaleOverlayRectPipe],
  templateUrl: './overlay-rect.component.html',
})
export class OverlayRectComponent {
  @Input({ required: true }) rect!: WordRect;
  @Input({ required: true }) pageId!: string;
  @Input() styleClasses = 'bg-gray-700 border';
}
