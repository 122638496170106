<div
  #pagePreviewList
  class="userselect-none relative flex h-full flex-col gap-4 overflow-y-auto rounded-r-lg bg-zinc-600 p-4 transition-all duration-300"
  [ngClass]="isPreviewListExpandedOrHovered ? 'w-[150px]' : 'w-0'"
>
  @if (isFieldExtractionView) {
    @for (document of documents; track document.id) {
      <app-image-list-preview-item-document
        class="transition-all duration-300"
        [ngClass]="isPreviewListExpandedOrHovered ? 'opacity-100' : 'opacity-0'"
        [selected]="document.id === selectedDocumentId"
        [typeThumbnail]="typeThumbnail(document.documentClass)"
        [documentType]="document.documentClass"
        (click)="scrollToFirstPageOfDocument($index)"
      />
    }
  } @else {
    @for (document of documents; track document.id) {
      @for (page of document.pages; track $index) {
        <app-image-list-preview-item-page
          class="transition-all duration-300"
          [ngClass]="isPreviewListExpandedOrHovered ? 'opacity-100' : 'opacity-0'"
          [page]="page"
          [selected]="$index === currentPageIndex"
          [index]="$index"
          (click)="scrollToPage($index)"
        />
      }
    }
  }
</div>
