import {
  AfterViewInit,
  Component,
  ElementRef,
  inject,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  ReactiveFormsModule,
  ValidatorFn,
} from '@angular/forms';
import { ButtonStateService } from '../../../services/app-state/button-state.service';
import { WorkflowService } from '../../../services/app-state/workflow.service';
import { ConfirmService } from '@l21s-ecnps/gui-commons';
import { CtBatch, CtDocument } from '../../../models/ct-batch-model';
import { ExtractedDataStoreService } from '../../../services/extracted-data/extracted-data-store.service';
import { distinctUntilChanged } from 'rxjs';
import { NgClass } from '@angular/common';
import { CalendarModule } from 'primeng/calendar';
import { ExtractedFields, FieldModel, fieldModels } from '../../../models/interfaces';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { PrimeNGConfig } from 'primeng/api';
import { defaultTranslation } from '../../../utils/PrimeNgTranslation.utils';
import { getInputClasses } from '../../../utils/InputValidation.utils';

@UntilDestroy()
@Component({
  selector: 'app-data-capturing-single-fields',
  templateUrl: './data-capturing-single-fields.component.html',
  standalone: true,
  styleUrl: './data-capturing-single-fields.component.scss',
  imports: [ReactiveFormsModule, NgClass, CalendarModule],
})
export class DataCapturingSingleFieldsComponent implements OnInit, AfterViewInit {
  private workflowService = inject(WorkflowService); //
  private confirmService = inject(ConfirmService); //
  private extractedDataStoreService = inject(ExtractedDataStoreService); //
  private fb = inject(FormBuilder); //
  private buttonStateService = inject(ButtonStateService); //
  private primeNgConfig = inject(PrimeNGConfig);

  @Input({ required: true }) ctBatch!: CtBatch;
  @ViewChild('formElementContainer') formElementContainer!: ElementRef<HTMLDivElement>;

  protected formGroup!: FormGroup;
  protected fieldModels = fieldModels;
  documents: CtDocument[] = [];
  currentFieldIndex = 0;
  birthDateAfterInvoiceDateMessage = 'Das Geburtsdatum muss vor dem Forderungsdatum liegen.';
  requiredErrorMessage = 'Dieses Feld ist ein Pflichtfeld.';

  ngOnInit(): void {
    const extractedFields = this.extractedDataStoreService.extractedFields;
    this.fillInputElementsInFormGroup(extractedFields);

    this.documents = this.ctBatch.documents;

    this.updateNextButtonState();

    this.formGroup.statusChanges
      .pipe(distinctUntilChanged())
      .pipe(untilDestroyed(this))
      .subscribe(() => this.updateNextButtonState());

    this.primeNgConfig.setTranslation(defaultTranslation);
  }

  ngAfterViewInit(): void {
    const initialInput = this.formElementContainer.nativeElement.children[0].querySelector('input');
    if (!initialInput) return;

    setTimeout(() => {
      initialInput.focus();
    });
  }

  fillInputElementsInFormGroup(extractedFields: ExtractedFields) {
    const getExtractedField = (model: FieldModel): string | Date | undefined => {
      const value = extractedFields[model.key as keyof ExtractedFields];
      if (value) {
        return model.type === 'date' ? new Date(value) : value;
      }
      return undefined;
    };

    const controls = Object.fromEntries(
      fieldModels.map((model) => [model.key, [getExtractedField(model), model.validations]]),
    );
    this.formGroup = this.fb.group(controls, {
      validators: [this.groupValidator],
    });
  }

  groupValidator: ValidatorFn = (group: AbstractControl) => {
    const birthDate = group.get('ClaimantBirthDate')!.value as Date | null;
    const invoiceDate = group.get('InvoiceDate')!.value as Date | null;
    if (birthDate && invoiceDate && birthDate.valueOf() > invoiceDate.valueOf()) {
      return { birthDateAfterInvoiceDate: true };
    }
    return null;
  };

  private updateNextButtonState() {
    this.buttonStateService.setNextButtonState({
      label: 'Weiter',
      isActive: this.isFormValid(),
      action: () => {
        this.goToNextView();
      },
    });
  }

  protected isFormValid(): boolean {
    return (
      Object.values(this.formGroup.controls).every((control) => {
        return control.valid;
      }) && this.formGroup.valid
    );
  }

  goToNextView() {
    if (this.isFormValid()) {
      this.extractedDataStoreService.extractedFields = this.formGroup.value as ExtractedFields;
      this.workflowService.navigateToNextView();
      return;
    }

    this.confirmService.snackError('Alle Felder müssen korrekt ausgefüllt werden');
  }

  get currentField() {
    return fieldModels[this.currentFieldIndex];
  }

  protected getControl(key: string): AbstractControl {
    return this.formGroup.controls[key];
  }

  private get currentControl(): AbstractControl {
    return this.getControl(this.currentField.key);
  }

  focusNextField() {
    if (this.currentControl.invalid) return;

    if (this.currentFieldIndex === fieldModels.length - 1) {
      this.goToNextView();
      return;
    }

    this.currentFieldIndex++;
    const nextInput =
      this.formElementContainer.nativeElement.children[this.currentFieldIndex].querySelector(
        'input',
      );

    nextInput?.focus();
  }

  onFieldInputClicked(index: number) {
    const clickedField =
      this.formElementContainer.nativeElement.children[index].querySelector('input');

    if (!clickedField) return;

    this.currentFieldIndex = index;
  }

  patchCurrentField(processedValue: unknown) {
    this.currentControl.patchValue(processedValue);
  }

  augmentCurrentField(processedValue: unknown) {
    const currentValue: unknown = this.currentControl.value;
    this.currentControl.patchValue(
      currentValue ? String(currentValue) + ' ' + String(processedValue) : processedValue,
    );
  }

  protected shouldDisplayFieldError(key: string) {
    return this.getControl(key).invalid && this.getControl(key).touched;
  }

  getInputClasses(value: string | Date | undefined, fieldIndex: number, displayError: boolean) {
    const isCurrentInput = this.currentFieldIndex === fieldIndex;
    return getInputClasses(value, isCurrentInput, displayError);
  }
}
