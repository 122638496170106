<div class="absolute flex flex-row" #menuDiv [ngStyle]="getMenuStyling(menuDiv)">
  @for (line of tableData.lines.vertical; track $index) {
    @if ($index < tableData.lines.vertical.length - 1) {
      <app-table-overlay-column-assignment-item
        [columnWidth]="
          (tableData.lines.vertical[$index + 1].offset - line.offset) * dimension.width
        "
        [tableData]="tableData"
        [columnIndex]="$index"
        [isMenuOpened]="openedMenuIndex === $index"
        (columnButtonClicked)="openMenuIfTableDataIsNotConfirmedAndCloseMenuIfOpened($event)"
        (newTableHeaderAtColumnPosition)="
          setNewTableHeaderAtColumnPosition($event.colPos, $event.newHeader)
        "
      />
    }
  }
</div>
