<header class="h-[64px] w-full border-b border-theme-gray-500 bg-white">
  <div class="max-w-screen-3xl mx-auto flex h-full items-center justify-between gap-4 p-4">
    <div class="flex w-fit items-center justify-start gap-4">
      <button (click)="unlockItemAndOpenProof()" class="cursor-pointer border-transparent bg-white">
        <img
          src="../../../../assets/images/eucon.png"
          alt="Eucon Logo"
          width="110"
          loading="lazy"
        />
      </button>
      <button mat-stroked-button (click)="unlockItemAndOpenProof()">Suche</button>
      <app-ct-batch-details [ctBatch]="ctBatch" />
    </div>
    <div class="flex items-center gap-4">
      <p class="text-sm">
        Angemeldet als <span class="font-semibold">{{ usersFullName }}</span>
      </p>
      <button mat-stroked-button (click)="logout()">
        <mat-icon>logout</mat-icon>
        <span>Ausloggen</span>
      </button>
    </div>
  </div>
</header>
